import {Form,Button} from "react-bootstrap"

export default function ButtonGroupComponent({data,label,category,click}) {
  return (
<section className='w-100'>
  <Form.Group className="mb-3" >
    <Form.Label>{label}</Form.Label>
      <div className='manufactured-products w-100 overflow-auto d-flex gap-1'>
        {data && data?.map((item,index) => (
        <Button className='text-capitalize' onClick={()=>{click(item?.id,index,category)}} key={index}>
          {item?.name}
        </Button>))}
        
      </div>
  </Form.Group>
</section>
  )
}
