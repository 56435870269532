/* eslint-disable react-hooks/exhaustive-deps */
import "../styles/PricePage.css"
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useProductsState } from "../store/accessors";
import { searchFilter, sortData } from "../services/utils";
import { useSelector } from "react-redux";
import CustomizedComponent from '../components/Shared/CustomizedComponent';
import ScrollBarComponent from "../components/Shared/ScrollBarComponent";
import CardComponent from "../components/Shared/CardComponent";
import { useEffect, useState } from "react";
import { getProducts } from "../services/apis/product";


export default function StockPage() {

  const price = useProductsState();

    useEffect(async() => {
      if (price?.length>0) {
        setLoading(false);
      } else {
        var result = await getProducts();
        setLoading(result && false );
      }
      }, []);

  const [loading,setLoading]=useState(true);

  const history = useHistory();
  const searchValue = useSelector((state) => state.search.value);
  const cardClick = (id)=>{history.push(`/price/${id}`)}

  const [filterItems,setFilterItems]=useState([
    {
      id:"5656156156154544",
      name:"50 ₺ veya daha fazla olanlar",
      price:50,
      selected:false
    },
    {
      id:"4629486315894986",
      name:"100 ₺ veya daha fazla olanlar",
      price:100,
      selected:false
    },
  ]);

  const [filterList,setFilterList]=useState(price);

    const sortClick = (desc) => {
      const sortedStalls = sortData(price, 'maxPrice', desc);
      setFilterList(sortedStalls);
    };

  const filterClick = (item)=>{
    const itemList=filterItems;
    const findItem=itemList.find(x=>x.id===item.id);
    findItem.id=item.id;
    setFilterItems(itemList);
    setFilterList(filteredPrices(price,itemList));
  }

  const filteredPrices = (stock, items) => {
  const selectedItems = items.filter((x) => x.selected === true);
  const filteredPrices = stock.filter((product) => {
  return selectedItems.every((selectedItem) => {
    return parseInt(product?.maxPrice) >= parseInt(selectedItem?.price);
  });
});

return filteredPrices;
};

    useEffect(() => {
    setFilterList(price);
    }, [price]);

    const matchingProducts = filterList?.filter((s) => searchFilter(s, searchValue) && s?.minStock);
    const matchingProductsCount = matchingProducts?.length;

    function convertedDateFormat(item) {
      return new Date(Date.parse(item+" UTC")).toLocaleString("tr-TR")
    }
    
  return (
    <div className='px-4' style={{overflowY:"hidden"}} >
    <Row className='w-100'>
            <div className='w-100 p-0'>
                <CustomizedComponent filterClick={filterClick} filterItems={filterItems} click={sortClick}/>
            </div>
            <ScrollBarComponent>
                <Row className='gx-5 px-2 ms-1 gy-4 py-4 pe-0' style={{width:"95%"}}>
                    <>
                  {loading ? <div className="fs-1 w-100 d-flex justify-content-center align-items-center" style={{height:"50vh"}}>Yükleniyor...</div> : <>
                  {matchingProductsCount > 0 ? matchingProducts?.map((item, index) => ( 
                    <Col key={index} xxl={4} xl={4} lg={4} className='m-0 mb-3 mt-2 card-container'>
                      <Row className='h-100 pages-card-container'>
                          <CardComponent logo={"Products"} shadow={"price-page-shadow"} description={`${item?.updateClock && "Güncelleme Saati : " + convertedDateFormat(item?.updateClock)}`} cardClick={cardClick} data={item} topInfo={`${item?.minPrice && item?.maxPrice ?  (`Min: ${parseFloat(item?.minPrice).toFixed(2)} ₺ - Max: ${parseFloat(item?.maxPrice).toFixed(2)} ₺` ) : ""}`}/>
                      </Row>
                    </Col>
                  )):<div className="fs-1 w-100 d-flex justify-content-center align-items-center ps-5" style={{height:"50vh"}}>Pazar kurulumu yok ya da ürün mevcut değil.</div>} </> }
                  </>
                </Row>
            </ScrollBarComponent>
    </Row>
</div>
  )
}
