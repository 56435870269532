/* eslint-disable react-hooks/exhaustive-deps */
import ModalStandDetailComponent from '../components/MarketSettlement/ModalStandDetailComponent';
import ScrollBarComponent from '../components/Shared/ScrollBarComponent';
import MarketSettlementLogo from '../components/Icons/MarketSettlementLogo';
import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap';
import { useStandsState } from '../store/accessors';
import { getStands } from '../services/apis/stand';
import { useLocation  } from 'react-router-dom';
import {  searchFilter } from '../services/utils';
import { useSelector } from 'react-redux';

export default function MarketSettlementPage() {
  
  const [showModal,setShowModal] = useState(false);
  const [item,setItem] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const searchValue = useSelector((state) => state.search.value);
  const stands = useStandsState();
  const filterList = stands;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const [loading,setLoading]=useState(true);

  useEffect(async() => {
    if (stands?.length>0) {
      setLoading(false);
    } else {
      var result = await getStands();
      setLoading(result && false );
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    }, []);

  useEffect(() => {
    if (id) {
      const filteredItem = stands.find((stand) => stand?.marketStallId === id);
      setItem(filteredItem);
      setShowModal(true);
    }
  }, [stands,id]);

 

  const handleClick= (item)=>{
   if (item?.whatDidIBrang?.length>0) {
    const filteredItem = stands?.find((stand) => stand?.farmerId === item?.farmerId);
    setItem(filteredItem);
    setShowModal(true);
   }else{return}
  }

  return (
    <Row id='market-settlement' className='m-2 mx-4 mb-5 d-flex align-items-center'>
        <ScrollBarComponent>
        <div className='d-flex flex-row flex-wrap gap-3'>
        <>
        {loading ? <div className='w-100 d-flex justify-content-center align-items-center fs-1' style={{height:"70vh"}}>Yükleniyor...</div> : <>
        {filterList?.filter((item) => searchFilter(item,searchValue))?.map((item,index)=>(
              <div onClick={() => (handleClick(item))} key={index} className='m-2 d-flex flex-column justify-content-center align-items-center m-0 p-0 '>
                <div className={`rounded-3 p-3 mb-1`} style={{cursor:"pointer",backgroundColor:`${item?.whatDidIBrang?.length>0 ? "white" : "rgba(217, 217, 217, 1)"}`}}>
                  <MarketSettlementLogo size={windowWidth < 1380 ? 40 :65} color={`${item?.whatDidIBrang?.length>0 ? "rgba(5, 27, 53, 1)" : "white"}  `}/>
                </div>
                <p className='text-center fw-bold m-0 p-0 stand-name' style={{color:`${item?.whatDidIBrang?.length>0 ? "rgba(5, 27, 53, 1)" : "rgba(217, 217, 217, 1)"}`}}>
                    {`Tezgah ${item?.marketStallName}`}
                </p>
            </div>
          ))}
        </>}
        </>
        </div>
        </ScrollBarComponent>
        <ModalStandDetailComponent data={item} showModal={showModal} setShowModal={setShowModal}/> 
    </Row>  
  )
}
