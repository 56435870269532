import { api } from "../api";
import { store } from "../../store";
import { setMarketInfos } from "../../store/slices/marketInfoSlice";

const ENDPOINT="market-places";
const dispatch=store.dispatch;
export const  getMarketInfos=async()=>{
 return await api.get(`/${ENDPOINT}`).then(({data})=>{
        dispatch(setMarketInfos(data.data));
            return true
    }).catch((err)=>{return err.message;})
}