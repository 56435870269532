import {Row, Col} from 'react-bootstrap';
import ViewerHeaderRightComponent from './ViewerHeaderRightComponent';
import ViewerHeaderLeftComponent from './ViewerHeaderLeftComponent';
export default function ViewerHeaderComponent() {
  return (
    <>
      <Row className='viewerheader-container'>
          <Col xxl={12} xl={12} lg={12} className='px-0'>
              <Row className='m-0 viewerheader px-4'>
                  <ViewerHeaderLeftComponent></ViewerHeaderLeftComponent>
                  <ViewerHeaderRightComponent></ViewerHeaderRightComponent>
              </Row>
          </Col>
      </Row>
    </>
  )
}
