/* eslint-disable react-hooks/exhaustive-deps */
import ScrollBarComponent from "../components/Shared/ScrollBarComponent";
import "../styles/MarketInfo.css";
import ModalSliderComponent from "../components/Modals/ModalSliderComponent";

export default function MarketInfoPage() {
  const marketInfoImages = [
    "market-image4",
    "market-image1",
    "market-image2",
    "market-image3",
    "market-image5",
    "market-image6",
    "market-image7",
  ];
  return (
    <section className="p-5 text-container" style={{ minHeight: "100vh" }}>
      <ScrollBarComponent>
        <div className="row mt-1 row">
          <div className="col-12 ">
            <div className="w-50 p-0 m-0 mx-5 side-image ">
              <div
                className="d-flex justify-content-center p-0 m-0"
                style={{ height: "50vh" }}
              >
                <div
                  className="position-relative"
                  style={{ margin: "0rem 5rem", width: "100%"}}
                >
                  <ModalSliderComponent
                    marketInfoImages={marketInfoImages}
                    slidesPerView={1}
                    size={50}
                    click={false}
                    customClass={"market-info-slider-item"}
                  />
                </div>
              </div>
            </div>
   <div className="pe-4">
   <h1>İBB ÜRETİCİ VE KOOPERATİF PAZARI</h1>
            <h2>PAZARDA KİMLER ÜRÜN SATABİLİR?</h2>
            <p className="market-info-text">
              İstanbul’da ikamet eden ve İstanbul’da üretim yapan Çiftçi Kayıt
              Sistemine (ÇKS) kayıtlı üreticiler. Türkiye geneli tarımsal ürün
              üreten ve işleyen Üretici Kooperatifleri.
            </p>
            <h2>PAZARDA HANGİ ÜRÜNLER SATILABİLİR?</h2>
            <p className="market-info-text">
              Çiftçiler sadece kendi ürettikleri ve ÇKS de belirtilen tarımsal
              ürünlerini. Kooperatifler; ortakları tarafından üretilen ve
              kooperatifin satması için verdikleri tarımsal ürünler ile yine
              ortaklarının üretmiş olduğu ürünlere ait işlenmiş tarım ürünleri.
            </p>
            <h2>MÜRACAAT NASIL ve NEREYE YAPILIR?</h2>
            <p className="market-info-text">
              Müracaatlar İstanbul Büyükşehir Belediyesi Tarımsal Hizmetler
              Dairesi Başkanlığı Tarım ve Su Ürünleri Müdürlüğü’ne bir dilekçe
              ve ekleri ile müracaat edilerek yapılır. Müracaat dilekçesi ve
              ekleri 0531 724 29 70 numaralı WhatsApp hattına iletilir.
              Yapılacak inceleme sonucunda değerlendirmenin sonucu telefon
              aracılığı ile tarafınıza iletilecektir. Müracaatı uygun olanlar
              evrak asıllarını Pazar’da bulunan İBB Tarım ve Su Ürünleri
              Müdürlüğü görevlilerine elden teslim edecekler.
            </p>
            <h2>
              ÜRETİCİ ve KOOPERATİF PAZARINDA DİKKAT EDİLMESİ GEREKEN KURALLAR
            </h2>
            <p className="market-info-text">
              Kendilerine pazarda yer verilen üretici ve kooperatifler
              komisyoncuların kullandığı depozitolu kasa ile kesinlikle ürün
              getirmeyeceklerdir. Üreticiler ÇKS kaydında olmayan kendilerinin
              ürettikleri ürünler dışında ürün satışı yapmayacaklardır. İşlenmiş
              ürünlerin etiketleri yeni olacak, son kullanma tarihi geçmiş ürün
              satılmayacaktır. Organik sertifikası bulunmayan ürünler organik
              etiketi ile satışa sunulmayacaktır. Üreticiler ve kooperatifler
              pazara gelirken üretici bilgi formunu doldurmuş olarak gelecekler,
              formları görevli personele teslim ettikten ve formda belirtilen
              ürünler kontrol edildikten sonra pazara giriş yapacaklardır.
              Formda bildirim yapılmayan ürünlerin satışına müsaade
              edilmeyecektir.
            </p>
            <h2>ÜRETİCİ PAZARINA İLİŞKİN GENEL ESASLAR</h2>
            <p className="market-info-text">
              <ul>
                <li>
                  Üretici pazarında yer alan üreticilerimiz her yılın Nisan ayı
                  sonuna kadar güncel ÇKS kaydını idareye teslim edeceklerdir.
                  Güncel ÇKS kaydını teslim etmeyenler tezgâh açma hakkını
                  kaybetmiş olacaklardır.
                </li>
                <li>
                  2B sorunu olan yerlerde Tarım ve Su Ürünleri Müdürlüğünün
                  tespiti esas alınır.
                </li>
                <li>
                  Üretici ve Kooperatifler pazarda kendilerine tahsis edilenler
                  satış tezgâhını kesinlikle bir başkasına kullandıramaz.
                  Tezgâhını başkasına kullandırdığı tespit edilenin tezgâh hakkı
                  başkaca bir uyarıya gerek kalmadan bir tam yıl süreyle askıya
                  alınır. Bu süre içerisinde üretici pazarında tezgâh açmasına
                  kesinlikle izin verilmez.
                </li>
                <li>
                  Üretici pazarında yer alacak kooperatifler için tarımsal ürün
                  ya da gıda üretimi esas olacaktır. Buna ilişkin hükümler ana
                  sözleşmelerinde yer alıyor olmalıdır. Bunların dışında
                  ürünlerin satılmasına izin verilmeyecektir.
                </li>
                <li>
                  Her bir üretici ya da kooperatife azami 2 (iki) tezgâh
                  verilecektir.
                </li>
                <li>
                  Pazarda satış yapılacak tezgâhların yeri İdare tarafından
                  belirlenecektir.
                </li>
                <li>
                  Tezgâh yerinin değiştirilmesi İdare’nin tasarrufunda
                  olacaktır.
                </li>
              </ul>
            </p>
   </div>
          </div>
        </div>
      </ScrollBarComponent>
    </section>
  );
}
