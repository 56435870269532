import { createSlice } from "@reduxjs/toolkit";

const productSlice=createSlice({
    name:"product",
    initialState:{
      // products:[
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "marketStallName":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "marketStallName":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //   {
      //       "id":"tomatoId",
      //       "name":"domates",
      //       "minPrice":"5",
      //       "maxPrice":"15",
      //       "minStock":"15",
      //       "maxStock":"45",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Domates",
      //           "stock":"50",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Domates",
      //           "stock":"30",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      //     {
      //       "id":"onionId",
      //       "name":"soğan",
      //       "minPrice":"40",
      //       "maxPrice":"50",
      //       "minStock":"70",
      //       "maxStock":"120",
      //       "useFulInfo":"çok sağlıklıdır",
      //       "updateClock":"13:25",
      //       "theStalls":[
      //         {
      //           "name":"1",
      //           "product":"Soğan",
      //           "stock":"10",
      //           "price":"50", 
      //         },
      //         {
      //           "name":"2",
      //           "product":"Soğan",
      //           "stock":"40",
      //           "price":"30", 
      //         },
      //       ]
      //     },
      // ],
      products:[]
    },
    reducers:{
        setProducts(state,actions){
            state.products=actions.payload;
        },
    }
})
export const {setProducts}=productSlice.actions;
export default productSlice.reducer;