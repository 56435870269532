import { Suspense, lazy, useEffect, useState } from "react";

export default function DynamicComponent({logo,color}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      },[]);

    const DynamicComponent = lazy(() =>
    import(`../Icons/${logo}Logo.js`).then((module) => ({
      default: module.default
      }))
    );

  return (
    <Suspense fallback={<div>Loading...</div>}>
        <DynamicComponent size={windowWidth > 1680 ? 70 : windowWidth < 1380 ? 25 :  50} color={color ? color : "rgba(0, 67, 140, 1)" }/>
    </Suspense>
  )
}
