/* eslint-disable react-hooks/exhaustive-deps */
import { lazy, Suspense, useEffect, useState} from 'react';
import { useBadgesState } from '../../store/accessors';
import { getBadges } from '../../services/apis/badges';


export default function RosettesComponent({click}) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loading,setLoading]=useState(true);
    const [activeBadge,setActiveBadge] = useState('');
    const badges = useBadgesState();

    useEffect(async() => {
      if (badges?.length>0) {
        setLoading(false);
      } else {
        var result = await getBadges();
        setLoading(result && false );
      }
      
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      }, []);


    const handleItemClick = (item) => {
        return () => {
            if (activeBadge?.length > 0 && activeBadge !== item?.name) {
                setActiveBadge(item?.name);
            }
            else if(activeBadge === item?.name){
                setActiveBadge(false);
            }
            else{
                setActiveBadge(item?.name);
            }
        };
      };

  useEffect(() => {
    click(activeBadge);
  }, [activeBadge]);
  
      return (
        <div className='d-flex rosettes'>
          <>
          {loading ? <div>Yükleniyor...</div> : <>
            {badges?.map((item, index) => {
              const DynamicComponent = lazy(() =>
                import(`../Icons/Rosettes/${item?.componentName ? item?.componentName : "FarmerManRosette" }.js`).then((module) => ({
                  default: module.default,
                }))
              );
              return (
                <Suspense key={index} fallback={<div>Yükleniyor...</div>}>
                  <div onClick={handleItemClick(item)} className='d-flex flex-column justify-content-start align-items-center text-center w-50' style={{cursor:"pointer"}}>
                    <DynamicComponent size={windowWidth<1250 ? 35 : 55}  color={(activeBadge === item?.name ) ? "rgba(0, 67, 140, 1)"   : "white"} bgColor={(activeBadge === item?.name ) ? "rgba(0, 67, 140, 1)" : "white"}/>
                    <h6 className='mt-2 fw-bolder rosette-text'>{item?.name}</h6>
                  </div>
                </Suspense>
              );
            })}
          </>}
          </>
        </div>
      );
      
}
