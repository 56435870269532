import {Modal,Row,Col, Image} from 'react-bootstrap';
import {lazy, Suspense} from 'react';
import config from '../../services/config'

export default function ModalHeaderComponent({logo,title,subTitle,img,qrCode,standNumber}) {
    const DynamicComponent = lazy(() =>
    import(`../Icons/${logo}Logo.js`).then((module) => ({
      default: module.default
    })));
    return (
    <Modal.Header className='w-100 position-relative border-0 p-4 pb-0'>
          <Modal.Title className='w-100 pe-3 me-3 mt-4 '>
            <Row className='d-flex'>
            <Col xxl={8} xl={8} lg={8} className='d-flex p-0 align-items-center'>
    <div id='modal-header' className='d-flex flex-row align-items-start justify-content-start flex-column p-1 ms-3 w-100'>
        <div className="d-flex flex-row  align-items-center">
            {img?.length > 0 ? (
                <Image src={`${config.API_URL}/file/${img}`} width={100} height={75} style={{objectFit:"contain"}}/>
            ) : (
                <Suspense fallback={<div>Yükleniyor...</div>}>
                    <DynamicComponent color={"black"} size={60} />
                </Suspense>
            )}
           </div>
           <div className='w-100 '>
            <h1 className='p-0 my-0 fs-3 fw-bold text-upperccaase' style={{ color: "rgba(5, 27, 53, 1)", marginLeft: '1rem' }}>{title}</h1>
              <div className='w-100 scrollable-h2 p-0 d-flex align-items-center'>
                <h2 className="fs-5 ms-3 text-capitalize">
                    {subTitle}
                </h2>
              </div>
        </div>
        
    </div>
</Col>
                {
                    qrCode &&
                    <Col xxl={4} xl={4} lg={4} className='d-flex flex-column justify-content-end align-items-end'>
                        <p className='fs-5'>Tezgah <span className='rounded text-white p-2' style={{backgroundColor:"rgba(64, 131, 32, 1)"}}>{standNumber}</span></p>
                        <p className='fs-5 m-0'>QR: {qrCode}</p>
                    </Col> 
                }
            </Row>
            </Modal.Title>
        </Modal.Header>
  )
}
