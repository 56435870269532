import axios from 'axios'
import config from './config'

const api = createApi();

function createApi() {
  const api = axios.create({
    baseURL: config.API_URL
  });
  return api
}
export {
  api
}
