import  "../styles/HomePage.css"
import TitleComponent from '../components/HomePage/TitleComponent'
import CardComponent from '../components/Shared/CardComponent';
import {Row,Col} from 'react-bootstrap';

export default function HomePage() {
    const left =[
      {
        id:2,
        icon:"MarketSettlement",
        name:"Tezgahlar",
        description:"Pazar kurulum düzenine göre tezgahları görüntüleyip üretici bilgilerine erişebilirsiniz.",
        logoColor:"white",
        col:12,
        pathName:"stand"
        },
        {
          id:5,
          icon:"Farmer",
          shadow:"farmer-shadow",
          name:"Çiftçi Bilgi",
          description:"Çiftçiler hakkında detaylı bilgiler",
          logoColor:"white",
          color:"rgba(0, 67, 140, 1)",
          col:6,
          pathName:"farmer"
          },
          {
          id:6,
          icon:"Products",
          shadow:"products-shadow",
          name:"Ürün Bilgi",
          description:"Ürünler hakkında detaylı bilgiler",
          logoColor:"white",
          color:"rgba(64, 131, 32, 1)",
          col:6,
          pathName:"products"
          },
    ]
     const right =[
        {
        id:1,
        icon:"MarketInfo",
        shadow:"market-info-shadow",
        name:"Pazar Bilgi",
        description:"Üretici/Kooperatif Pazarı sistemi hakkında bilgilendirmeler",
        logoColor:"white",
        color:"rgba(64, 131, 32, 1)",
        col:12,
        pathName:"market-info"
        },
        
        {
        id:3,
        icon:"Stock",
        shadow:"stock-shadow",
        name:"Stok Takibi",
        description:"Satıştaki ürünlerin kalan stok miktarı",
        logoColor:"white",
        color:"rgba(0, 67, 140, 1)",
        col:6,
        pathName:"stock"
        },
        {
        id:4,
        icon:"Price",
        shadow:"price-shadow",
        name:"Fiyat Bilgisi",
        description:"Satıştaki ürünlerin fiyat bilgileri",
        logoColor:"white",
        color:"rgba(0, 102, 214, 1)",
        col:6,
        pathName:"price"
      },
    
    ];
 
  return (
      <Row style={{height:"87vh"}}>
        <Col xxl={6} xl={6} lg={6} className='home-page-col-1'>
          <Row className='h-100 home-page-col-1-item'>
            <TitleComponent title="TOPRAKTAN FİLEYE" subTitle="Gıda İzleme Modeli" description="Uygulamasına Hoşgeldiniz..."/>
            {left.map((item,index) => (<CardComponent logo={item.icon} shadow={item?.shadow} description={item?.description} data={item} key={index}></CardComponent> ))}
          </Row>
        </Col>

        <Col xxl={6} xl={6} lg={6}  className='home-page-col-2'>
          <Row className='h-100 home-page-col-2-item'>
            {right.map((item,index) => (<CardComponent logo={item.icon} shadow={item?.shadow} description={item?.description} data={item} key={index}/> ))}
          </Row>
        </Col>
      </Row>
  )
}
