export default function PdfsLogoComponent({size,data,title}) {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center me-4" style={{cursor:"pointer"}}>
      <svg width={size} height={size} viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.4199 0.169556L64.5225 13.8279V65.7672H15.5762V65.9368H64.6897V13.9998L51.4199 0.169556Z" fill="#909090"/>
      <path d="M51.2566 0H15.4082V65.7672H64.5217V13.8302L51.2566 0Z" fill="#F4F4F4"/>
      <path d="M15.0477 3.53235H0V19.6091H47.3332V3.53235H15.0477Z" fill="#7A7B7C"/>
      <path d="M47.5857 19.336H0.306641V3.24744H47.5857V19.336Z" fill="#DD2025"/>
      <path d="M15.9851 5.96718H12.9072V17.2706H15.328V13.4581L15.8626 13.4887C16.382 13.4797 16.8966 13.3867 17.3862 13.2132C17.8155 13.0655 18.2105 12.8324 18.5472 12.5279C18.8898 12.2378 19.1599 11.8717 19.3361 11.4588C19.5722 10.7723 19.6566 10.0428 19.5833 9.32053C19.5686 8.8046 19.4782 8.29362 19.3149 7.80399C19.1662 7.45053 18.9456 7.1319 18.6671 6.86837C18.3885 6.60484 18.0582 6.40219 17.6971 6.27331C17.3848 6.16027 17.0622 6.07824 16.7339 6.0284C16.4853 5.99004 16.2343 5.96958 15.9827 5.96718M15.5376 11.3693H15.328V7.88405H15.7825C15.9831 7.86959 16.1844 7.90038 16.3715 7.97416C16.5586 8.04793 16.7267 8.1628 16.8634 8.31029C17.1468 8.68946 17.2981 9.15099 17.2944 9.62431C17.2944 10.2036 17.2944 10.7288 16.7716 11.0985C16.395 11.3056 15.9663 11.4012 15.5376 11.3693ZM24.1824 5.93656C23.921 5.93656 23.6667 5.9554 23.4877 5.96247L22.9273 5.9766H21.0905V17.28H23.2522C24.0784 17.3027 24.9009 17.1627 25.6731 16.8679C26.2945 16.6214 26.8449 16.2241 27.2744 15.7117C27.6921 15.1947 27.9918 14.5927 28.1528 13.9479C28.3378 13.2176 28.428 12.4665 28.4212 11.7131C28.4669 10.8233 28.398 9.93136 28.2163 9.05914C28.0439 8.41711 27.721 7.8254 27.2744 7.33301C26.924 6.93542 26.495 6.6147 26.0145 6.39106C25.6019 6.20011 25.1678 6.05938 24.7217 5.97188C24.5443 5.94258 24.3646 5.92918 24.1848 5.93185M23.7562 15.203H23.5207V7.98767H23.5513C24.0368 7.93181 24.528 8.01941 24.9642 8.23964C25.2837 8.49474 25.544 8.81611 25.7272 9.18159C25.9249 9.56623 26.0389 9.98839 26.0616 10.4203C26.0828 10.9383 26.0616 11.3622 26.0616 11.7131C26.0712 12.1173 26.0452 12.5215 25.9839 12.9212C25.9113 13.3314 25.7771 13.7284 25.5859 14.0986C25.3695 14.4428 25.0771 14.7328 24.7311 14.9464C24.4406 15.1343 24.0965 15.2219 23.7515 15.196M35.7143 5.9766H29.9919V17.28H32.4127V12.7963H35.4741V10.6958H32.4127V8.07715H35.7096V5.9766" fill="#464648"/>
      <path d="M45.9584 42.9885C45.9584 42.9885 53.4658 41.6274 53.4658 44.1919C53.4658 46.7563 48.8149 45.7131 45.9584 42.9885ZM40.408 43.184C39.2152 43.4475 38.0528 43.8334 36.9392 44.3355L37.8812 42.2161C38.8231 40.0967 39.8004 37.2073 39.8004 37.2073C40.9244 39.099 42.2322 40.8754 43.7048 42.5105C42.5943 42.6761 41.4937 42.9025 40.408 43.1887V43.184ZM37.4361 27.8772C37.4361 25.6424 38.159 25.0325 38.7219 25.0325C39.2847 25.0325 39.9181 25.3033 39.9393 27.2438C39.756 29.1949 39.3474 31.1183 38.7219 32.9756C37.865 31.4162 37.4215 29.6635 37.4337 27.8843L37.4361 27.8772ZM26.4882 52.6412C24.1852 51.2636 31.3181 47.0224 32.6109 46.8859C32.6039 46.8882 28.8996 54.0824 26.4882 52.6412ZM55.6582 44.4957C55.6346 44.2602 55.4227 41.6533 50.7836 41.764C48.8499 41.7328 46.9172 41.8691 45.0071 42.1714C43.1567 40.3073 41.5633 38.2046 40.269 35.9192C41.0845 33.5629 41.5779 31.1073 41.7361 28.619C41.6678 25.7932 40.992 24.173 38.8255 24.1965C36.659 24.2201 36.3434 26.1158 36.6284 28.9369C36.9075 30.8327 37.434 32.6837 38.1944 34.4426C38.1944 34.4426 37.1935 37.5582 35.8701 40.6572C34.5467 43.7562 33.6424 45.3811 33.6424 45.3811C31.341 46.1303 29.1745 47.2433 27.2253 48.6779C25.2849 50.4841 24.496 51.8712 25.518 53.2582C26.3988 54.4545 29.4813 54.7253 32.2365 51.1152C33.7004 49.2507 35.0379 47.2902 36.2398 45.2469C36.2398 45.2469 40.4409 44.0953 41.7479 43.7798C43.0549 43.4642 44.635 43.2146 44.635 43.2146C44.635 43.2146 48.4711 47.0743 52.1706 46.9377C55.8701 46.8011 55.6912 44.7264 55.6676 44.5004" fill="#DD2025"/>
      <path d="M51.0762 0.181274V14.0115H64.3412L51.0762 0.181274Z" fill="#909090"/>
      <path d="M51.2588 0V13.8302H64.5239L51.2588 0Z" fill="#F4F4F4"/>
      <path d="M15.8015 5.78602H12.7236V17.0895H15.1539V13.2793L15.6908 13.3099C16.2102 13.3009 16.7247 13.2079 17.2144 13.0344C17.6437 12.8866 18.0386 12.6535 18.3754 12.3491C18.7154 12.0582 18.9831 11.6922 19.1572 11.28C19.3934 10.5935 19.4777 9.86397 19.4044 9.14173C19.3897 8.6258 19.2993 8.11482 19.136 7.62519C18.9873 7.27174 18.7667 6.95311 18.4882 6.68958C18.2097 6.42605 17.8793 6.22339 17.5182 6.09451C17.2045 5.98037 16.8803 5.89755 16.5503 5.84725C16.3017 5.80889 16.0507 5.78843 15.7991 5.78602M15.354 11.1881H15.1445V7.7029H15.6013C15.8019 7.68843 16.0032 7.71923 16.1903 7.793C16.3773 7.86678 16.5455 7.98165 16.6822 8.12913C16.9655 8.50831 17.1169 8.96984 17.1131 9.44316C17.1131 10.0225 17.1131 10.5476 16.5904 10.9173C16.2137 11.1245 15.785 11.2177 15.3564 11.1858M23.9988 5.75541C23.7374 5.75541 23.4831 5.77425 23.3041 5.78131L22.7507 5.79544H20.9139V17.0989H23.0757C23.9019 17.1215 24.7244 16.9815 25.4965 16.6868C26.118 16.4403 26.6683 16.0429 27.0979 15.5305C27.5155 15.0135 27.8153 14.4116 27.9762 13.7667C28.1612 13.0364 28.2515 12.2853 28.2447 11.5319C28.2904 10.6422 28.2215 9.75021 28.0398 8.87799C27.8674 8.23596 27.5445 7.64425 27.0979 7.15186C26.7474 6.75426 26.3185 6.43355 25.838 6.2099C25.4254 6.01896 24.9913 5.87823 24.5452 5.79073C24.3677 5.76142 24.188 5.74803 24.0082 5.7507M23.5797 15.0219H23.3442V7.80651H23.3748C23.8603 7.75066 24.3515 7.83825 24.7877 8.05849C25.1072 8.31358 25.3675 8.63496 25.5507 9.00044C25.7484 9.38508 25.8624 9.80724 25.8851 10.2391C25.9063 10.7572 25.8851 11.1811 25.8851 11.5319C25.8947 11.9361 25.8687 12.3404 25.8074 12.74C25.7348 13.1503 25.6006 13.5473 25.4094 13.9174C25.193 14.2616 24.9005 14.5516 24.5546 14.7652C24.264 14.9531 23.92 15.0408 23.5749 15.0148M35.5307 5.79544H29.8083V17.0989H32.2291V12.6152H35.2905V10.5146H32.2291V7.896H35.526V5.79544" fill="white"/>
      </svg>
    <p className="pdf-title mt-1 fw-semibold">{data?.filename ? data?.filename : title }</p>
    </div>
  )
}
