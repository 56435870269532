import { useSelector } from "react-redux";

export function useSearchState(){
    return useSelector(state=>{
        return state.search.search
    })
}

export function useFarmersState(id=null){
    return useSelector(state=>{
        return id?state.farmer.farmers.find(f=>f.id===id):state.farmer.farmers
    })
}

export function useProductsState(id=null){
    return useSelector(state=>{
        return id?state.product.products.find(p=>p.id===id):state.product.products
    })
}

export function useStandsState(id=null){
    return useSelector(state=>{
        return id?state.stand.stands.find(s=>s.id===id):state.stand.stands
    })
}
export function useMarketInfoState(id=null){
    return useSelector(state=>{
        return id?state.marketInfo.marketInfos.find(m=>m.id===id):state.marketInfo.marketInfos
    })
}

export function useBadgesState(id=null){
    return useSelector(state=>{
        return id?state.badge.badges.find(b=>b.id===id):state.badge.badges
    })
}

