import {Modal,Button,Row,Col,Form} from 'react-bootstrap';
import ModalInputComponent from '../Modals/ModalInputComponent';
import ButtonGroupComponent from '../Modals/ModalButtonGroupComponent';
import ModalHeaderComponent from '../Modals/ModalHeaderComponent';
import ModalPhotoDisplayComponent from '../Modals/ModalPhotoDisplayComponent';
import ModalCloseComponent from '../Modals/ModalCloseComponent';
import ModalComponent from '../Modals/ModalComponent';


import ModalAnalysisDetailComponent from '../Modals/ModalAnalysisDetailComponent';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function ModalProductsDetailComponent({showModal,setShowModal,data,farmer,standNumber,qrCode,marketStallId}) {


  const product = data?.product[0];  
  const [showAnalysisModal,setShowAnalysisModal] = useState(null); 
  const [selectedAnalysis,setSelectedAnalysis] = useState({}); 
  const handleClose = () => setShowModal(false);
  const history = useHistory();
  const [category,setCategory] = useState(''); 
  let date = product?.harvestDate?.split(" ")[0];
  if (date) {
    let dateSplit = date?.split("/");
    date = `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`
  }
  
const analysisClick = (id,index,category)=>{
    setSelectedAnalysis(product?.analysis[parseInt(index)]);
    setShowAnalysisModal(true);
    setCategory(category);
}
const fertilizationsClick = (id,index,category)=>{
    setSelectedAnalysis(product?.fertilizations[parseInt(index)]);
    setShowAnalysisModal(true); 
    setCategory(category); 
}
const sprayingClick = (id,index,category)=>{
    setSelectedAnalysis(product?.spraying[parseInt(index)]);
    setShowAnalysisModal(true); 
    setCategory(category);
}

const goToStand = ()=>{
    history.push(`/stand?id=${marketStallId}`)
}

  return (
    <div>
        <ModalComponent showModal={showModal} handleClose={handleClose}>
            <ModalHeaderComponent title={data?.name} subTitle={farmer?.fullName} img={data?.productLogo&&data?.productLogo} logo={"Products"} standNumber={standNumber} qrCode={qrCode} />
            <ModalCloseComponent handleClose={handleClose}></ModalCloseComponent>
            <Modal.Body className='px-4'>
                <Row className='position-relative h-100'>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form>
                            <ModalInputComponent label={"Üretildiği Yerler"} placeholder={product?.whereProduced} ></ModalInputComponent>
                            <ModalInputComponent label={"Hasat Tarihi"} placeholder={date&&date} ></ModalInputComponent>
                            {product?.analysis?.length>0 &&   <ButtonGroupComponent category={"Analysis"} click={analysisClick} data={product?.analysis} label={"Toprak Analizi"}/>}
                            {product?.fertilizations?.length>0 &&   <ButtonGroupComponent category={"Fertilizations"} click={fertilizationsClick} data={product?.fertilizations} label={"Gübreleme"} />}
                            {product?.spraying?.length>0 &&   <ButtonGroupComponent category={"Spraying"} click={sprayingClick} data={product?.spraying} label={"İlaçlama"} />}
                        </Form>
                    </Col>

                    <div className='position-absolute bg-black h-100 p-0 m-0 x-line' style={{width:"1px",left:"50%"}}></div>
                        <div className='position-absolute p-0 m-0  w-100 d-flex justify-content-center align-items-center' style={{height:"1px",top:"-2%"}}>
                                <div className='bg-black h-100 y-line' style={{width:"98%"}}></div>
                        </div>

                    <Col xxl={6} xl={6} lg={6} className='h-100 d-flex flex-column justify-content-between'>
                    {product?.productImageList?.filter(file => file?.extension === '.jpg' || '.png' || 'jpeg').length > 0  && <ModalPhotoDisplayComponent label={"Üretim Fotoğrafları"} customClass="production-photos" images={product?.productImageList} />}
                       {marketStallId &&
                         <section className='w-100 h-100 d-flex pe-5 justify-content-end align-items-end'>
                            <Button onClick={goToStand} className='fw-bold fs-3 border-0 p-2 mt-4 px-5 mb-3 modal-button'>
                                Tezgaha Git
                            </Button>
                        </section>   
                       }
                    </Col>
                </Row>
            </Modal.Body>
        </ModalComponent>
    <ModalAnalysisDetailComponent  img={data?.productLogo&&data?.productLogo} category={category} farmerId={farmer?.id} data={selectedAnalysis} product={data?.name} showModal={showAnalysisModal} setShowModal={setShowAnalysisModal}/>
    </div>
  )
}
