import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ModalComponent from "../Modals/ModalComponent";
import ModalCloseComponent from "../Modals/ModalCloseComponent";
import config from "../../services/config";
import "react-pdf/dist/esm/Page/TextLayer.css";
import axios from "axios";

export default function PdfViewerComponent({
  showModal,
  setShowModal,
  documentId,
}) {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js',import.meta.url,).toString();
  }, []);

  useEffect(() => {
    if(documentId){
      getPdf();
    }
    
  }, [documentId]);

  const [numPages, setNumPages] = useState(null);
  const [file, setFile] = useState(null);

  const handleClose = () => setShowModal(false);

  const handleLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const getPdf = async () => {
    try {
      const response = await axios.get(`${config.API_URL}/file/${documentId}`, {
        responseType: "arraybuffer",
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      });

      const blob = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(blob);
      setFile(fileURL);
    } catch (error) {
      console.error("Error fetching the PDF file:", error);
    }
  };

  return (
    <ModalComponent
      showModal={showModal}
      handleClose={handleClose}
      backgroundColor="rgba(0, 67, 140, 0.5)"
    >
      <ModalCloseComponent handleClose={handleClose} />
      <div className="m-5 overflow-auto">
        <Document
          file={file}
          onLoadSuccess={handleLoadSuccess}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="pdf-pages-container">
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="pdf-page"
              />
            ))}
          </div>
        </Document>
      </div>
    </ModalComponent>
  );
}
