import ModalCloseComponent from "./ModalCloseComponent";
import ModalComponent from "./ModalComponent"
import ModalSliderComponent from "./ModalSliderComponent"

export default function ModalOneItemShowSliderComponent({showModal,setShowModal,images,marketInfoImages,clickedIndex}) {
    const handleClose = ()=>setShowModal(false);
  return (
    <ModalComponent showModal={showModal} handleClose={handleClose}>
      <ModalCloseComponent handleClose={handleClose}></ModalCloseComponent>
     <section className='w-100 h-100 d-flex justify-content-center align-items-center'>
     <div className='w-75 h-100 position-relative '>
        <ModalSliderComponent  initialSlide={clickedIndex!==0&&clickedIndex} click={false} images={images} marketInfoImages={marketInfoImages} slidesPerView={1} customClass={"one-item-slider"} size={120}></ModalSliderComponent>
        </div>
     </section>
    </ModalComponent>
  )
}
