import React from 'react'
import { Image, NavLink } from 'react-bootstrap'

export default function DownloadIstanbulSeninComponent() {
  return (
    <div className="position-absolute top-0 bottom-0 w-100 h-100 bg-white p-0 m-0 " style={{ minWidth: "100vw" }}>
    <div className="notification h-100">
        <Image src='/assets/phone.png' className='w-100 mt-4 object-fit-contain' />
        <div className='w-100 text-center' style={{marginTop:"-4rem"}}><Image src='/assets/istanbul-senin-logo.png' className='w-25 object-fit-contain'/></div>
        <div className='w-100 px-2 text-center'><p className='fs-4 fw-bold'>İstanbul Senin Uygulamasını İndirip <span style={{color:"rgba(64, 131, 32, 1)"}}>“Topraktan Fileye Gıda İzleme Modeli”</span> uygulamamızı kullanabilirsiniz.</p></div>
        <div className='w-100 text-center d-flex flex-column justify-content-center align-items-center'>
            <NavLink className='w-75' target='_blank' href="https://play.google.com/store/apps/details?id=com.tr.gov.ibb.istanbulsenin&hl=en_AU&gl=TR"><Image src='/assets/stores/google-play.svg' ></Image></NavLink>
            <NavLink className='w-75 mt-2' target='_blank' href="https://apps.apple.com/tr/app/lstanbul-senin/id1534342254?l=tr"><Image src='/assets/stores/app-store.svg' ></Image></NavLink>
        </div>
    </div>
  </div>
  )
}
