export default function ProductsLogo({size,color}) {
  return (
      <svg width={size} height={size} viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5966 4.83447C18.8516 6.904 19.1423 9.64002 19.7929 11.4447C17.439 11.129 15.3697 11.8539 14.5092 13.5302C13.4538 15.5862 14.5476 18.4266 16.98 20.4804C15.5592 20.9107 14.4237 21.7547 13.7885 22.9923C12.4176 25.6627 13.3164 28.2484 15.17 30.3599M43.0617 17.9874C42.6637 16.6419 41.8313 15.4871 40.4198 14.6991C39.2245 14.0317 37.8248 13.9771 36.4105 14.4297C37.3282 11.3328 36.7083 8.34477 34.7221 7.23588C33.1029 6.33179 30.9902 6.90919 29.2013 8.51506C28.5127 5.52003 25.7721 1.06246 22.2019 2.17419" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.6634 24.1489L25.5845 13.6783" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34.5007 23.1488L30.9648 27.2388" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M29.7808 14.627L26.8577 18.0081L22.6035 16.862" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M36.7132 34.4135C35.6099 32.7831 33.3313 32.3214 30.8685 32.9895C31.646 29.8212 31.1769 24.2865 27.2614 23.6354C23.3468 22.9845 20.942 28.0389 20.5501 31.2736C18.4693 29.8501 16.1614 29.5491 14.545 30.7272C12.5625 32.1723 12.2428 35.3875 13.5679 38.4961C12.3392 38.282 11.1561 38.3989 10.1245 38.8881" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.731 48.4357L24.974 36.1571" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M28.4986 39.0607L24.1127 40.87L20.627 37.7516" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M64.0523 49.897L60.4242 40.0642L49.2774 9.85464C48.7154 8.33178 46.5616 8.33178 45.9996 9.85464L34.8528 40.0642L32.2773 47.0441" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M47.5786 35.5238V33.2493" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M52.6994 41.7214L52.6772 39.2144" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M42.4808 41.7214L42.4585 39.2144" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M57.4113 60.3379C60.0595 59.8776 62.679 59.2286 65.2457 58.3908C66.1904 58.0824 66.6935 57.0544 66.3496 56.1221L64.0527 49.897C55.6804 52.456 46.8492 53.0115 38.2778 51.5632" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.28321 25.3275C5.54882 27.2361 4.15072 29.5092 3.21952 32.0883C3.0526 32.5507 2.90436 33.015 2.77246 33.4805L8.04815 33.4925C7.75569 31.1803 7.92922 28.7797 8.6367 26.4273L7.28321 25.3275Z" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M34.8776 54.955C36.3806 53.7759 37.7272 52.3771 38.8627 50.7838C39.7314 49.5648 39.6865 47.9167 38.7587 46.742L38.7396 46.7179C37.6652 45.3578 35.7444 45.0503 34.2753 45.97C29.9884 48.6535 24.5713 49.4293 19.4482 47.6363C13.0877 45.4103 8.84309 39.7786 8.04793 33.4923L2.77225 33.4802C0.454226 41.6617 3.49308 50.2208 10.0313 55.1667" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.2276 57.5125C20.2097 54.6602 15.0473 52.32 13.2618 54.3448C12.9519 54.6962 12.951 55.2041 13.2028 55.7841C13.0275 55.7315 12.8486 55.6814 12.6662 55.6342C9.7138 54.8709 6.99581 55.152 6.59519 56.2618C6.3834 56.8485 6.86223 57.5494 7.79641 58.1925C7.72625 58.1917 7.65608 58.1908 7.58527 58.191C4.49791 58.1981 1.99741 58.8889 2 60.0491C2.0026 61.2095 4.50763 61.8888 7.595 61.8818C7.66581 61.8817 7.73597 61.8805 7.80614 61.8793C6.87481 62.5268 6.39922 63.2297 6.61373 63.8155C7.01928 64.9235 9.73858 65.1922 12.6874 64.4156C12.8696 64.3676 13.0484 64.3168 13.2234 64.2634C12.9742 64.8445 12.9894 65.3449 13.2889 65.7024C14.9463 67.6798 20.3412 64.8094 22.1741 62.5715" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M53.293 66.4578H60.1364C61.6261 66.4578 62.8336 65.2502 62.8336 63.7605C62.8336 62.512 61.9767 61.4267 60.7623 61.137L29.6849 53.7231C25.6277 52.7552 21.7314 55.8313 21.7314 60.0024C21.7314 63.5677 24.6217 66.4579 28.187 66.4579H49.4089" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M51.0747 59.2145V61.9138" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M43.0586 57.1048V60.291" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M35.043 55.166V58.668" stroke={color} strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}