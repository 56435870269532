export default function MarketInfoLogo({size,color}) {
  return (
      <svg width={size} height={size} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.0255 50.7796C44.0255 57.5254 38.5579 62.9942 31.8134 62.9942C25.069 62.9942 19.6016 57.5254 19.6016 50.7796C19.6016 44.0337 25.069 38.565 31.8134 38.565C38.5579 38.565 44.0255 44.0337 44.0255 50.7796Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M31.8193 49.0962V57.8827" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M33.0983 44.9614C33.0983 45.671 32.523 46.2464 31.8133 46.2464C31.1037 46.2464 30.5283 45.671 30.5283 44.9614C30.5283 44.2517 31.1037 43.6764 31.8133 43.6764C32.523 43.6764 33.0983 44.2517 33.0983 44.9614Z" fill={color}/>
        <path d="M15.5031 59.26C14.157 56.6802 13.396 53.7455 13.396 50.632C13.396 40.3363 21.7198 31.9893 31.9896 31.9893C42.2582 31.9893 50.5832 40.3363 50.5832 50.632C50.5832 53.7455 49.8223 56.6802 48.4762 59.26" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.7915 16.3284H13.3957M25.7915 16.3284L28.0492 1.00002M25.7915 16.3284L25.7915 19.7015M25.7915 16.3284H38.1872M13.3957 16.3284L19.5428 1.00002M13.3957 16.3284V19.7015M13.3957 16.3284L1 16.3283M19.5428 1.00002H28.0492M19.5428 1.00002L11.0366 1L1 16.3283M28.0492 1.00002H35.7703M38.1872 16.3284H50.5829M38.1872 16.3284L35.7703 1.00002M38.1872 16.3284L38.1872 19.7015M50.5829 16.3284L44.5951 1.00002M50.5829 16.3284L50.583 19.7015M50.5829 16.3284H62.9787M44.5951 1.00002H35.7703M44.5951 1.00002H53.1015L62.9787 16.3284M13.3957 19.7015C13.3957 23.088 16.1707 25.8334 19.5936 25.8334M13.3957 19.7015C13.3957 23.088 10.6208 25.8334 7.19789 25.8334C3.77495 25.8334 1.00002 23.088 1.00002 19.7015L1 16.3283M13.3957 19.7015C13.3957 21.394 12.7017 22.9271 11.5805 24.0376C10.5009 25.1044 8.83053 25.7799 7.19792 25.8298V48.1038H13.0444C14.2431 38.8337 22.2463 31.6687 31.9428 31.6687C41.6382 31.6687 49.6424 38.8337 50.8411 48.1038H56.7809V42.7049M19.5936 25.8334C23.0165 25.8334 25.7915 23.088 25.7915 19.7015M19.5936 25.8334C21.3043 25.8334 22.8538 25.1468 23.9763 24.0376C25.0974 22.9271 25.7915 21.394 25.7915 19.7015M25.7915 19.7015C25.7915 23.088 28.5665 25.8334 31.9894 25.8334M38.1872 19.7015C38.1872 23.088 40.9622 25.8334 44.3851 25.8334M38.1872 19.7015C38.1872 23.088 35.4123 25.8334 31.9894 25.8334M38.1872 19.7015C38.1872 21.394 37.4932 22.9271 36.372 24.0376C35.2496 25.1468 33.7001 25.8334 31.9894 25.8334M44.3851 25.8334C47.8081 25.8334 50.583 23.088 50.583 19.7015M44.3851 25.8334C46.0958 25.8334 47.6453 25.1468 48.7677 24.0376C49.8889 22.9271 50.583 21.394 50.583 19.7015M50.583 19.7015C50.583 23.088 53.3579 25.8334 56.7808 25.8334M62.9787 16.3284L62.9787 19.7015C62.9787 23.088 60.2038 25.8334 56.7808 25.8334M56.7808 25.8334L56.7809 31.7421" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}
