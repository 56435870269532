import MainHeaderComponent from '../components/MainHeaderComponent'

export default function MainLayout({children}) {
  return (
    <div className='px-5 overflow-hidden'>
      <MainHeaderComponent/> 
      {children}
    </div>
  )
}
