import React, { useEffect, useState} from 'react';
import {NavLink} from "react-router-dom"
import { useLocation } from 'react-router-dom';
import MarketSettlementLogo from '../Icons/MarketSettlementLogo';
import ProductsLogo from '../Icons/ProductsLogo';
import FarmerLogo from '../Icons/FarmerLogo';
import MarketInfoLogo from '../Icons/MarketInfoLogo';
import StockLogo from '../Icons/StockLogo';
import PriceLogo from '../Icons/PriceLogo';

export default function HeaderItemComponent() {
  const location = useLocation();
  const { pathname } = location;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);


  return (
    <>
      <NavLink id='link' to={`/stand`} className="d-flex viewerheader-left-item justify-content-center align-items-center position-relative">
        <div className="d-flex justify-content-center align-items-center flex-column px-3">
          <MarketSettlementLogo color={pathname.includes("stand") ? "white" : "rgba(5, 27, 53, 1)"}  size={windowWidth > 1440 ? 40 : 25}/>
          <div id='border' className='position-absolute top-0'/>
        <h3 className={`${pathname.includes("stand") ? "text-white" : ""} mt-2 text-center viewheader-item-title`} >{"Tezgahlar"}</h3>
        </div>
          <div className={` position-absolute `} style={{backgroundColor:"black",height:"60%",width:"1px",right:"-10px"}}></div>
      </NavLink>
      <NavLink id='link' to={`/farmer`} className="d-flex viewerheader-left-item justify-content-center align-items-center position-relative">
        <div className="d-flex justify-content-center align-items-center flex-column px-3">
          <FarmerLogo color={pathname.includes("farmer") ? "white" : "rgba(5, 27, 53, 1)"}  size={windowWidth > 1440 ? 40 : 25}/>
          <div id='border' className='position-absolute top-0'/>
        <h3 className={`${pathname.includes("farmer") ? "text-white" : ""} mt-2 text-center viewheader-item-title`} >{"Çiftçiler"}</h3>
        </div>
          <div className={` position-absolute `} style={{backgroundColor:"black",height:"60%",width:"1px",right:"-10px"}}></div>
      </NavLink>
      <NavLink id='link' to={`/products`} className="d-flex viewerheader-left-item justify-content-center align-items-center position-relative">
        <div className="d-flex justify-content-center align-items-center flex-column px-3">
          <ProductsLogo color={pathname.includes("products") ? "white" : "rgba(5, 27, 53, 1)"}  size={windowWidth > 1440 ? 40 : 25}/>
          <div id='border' className='position-absolute top-0'/>
        <h3 className={`${pathname.includes("products") ? "text-white" : ""} mt-2 text-center viewheader-item-title`} >{"Ürünler"}</h3>
        </div>
          <div className={` position-absolute `} style={{backgroundColor:"black",height:"60%",width:"1px",right:"-10px"}}></div>
      </NavLink>
      <NavLink id='link' to={`/market-info`} className="d-flex viewerheader-left-item justify-content-center align-items-center position-relative">
        <div className="d-flex justify-content-center align-items-center flex-column px-3">
          <MarketInfoLogo color={pathname.includes("market-info") ? "white" : "rgba(5, 27, 53, 1)"}  size={windowWidth > 1440 ? 40 : 25}/>
          <div id='border' className='position-absolute top-0'/>
        <h3 className={`${pathname.includes("market-info") ? "text-white" : ""} mt-2 text-center viewheader-item-title`} >{"Pazar Bilgi"}</h3>
        </div>
          <div className={` position-absolute `} style={{backgroundColor:"black",height:"60%",width:"1px",right:"-10px"}}></div>
      </NavLink>
      <NavLink id='link' to={`/stock`} className="d-flex viewerheader-left-item justify-content-center align-items-center position-relative">
        <div className="d-flex justify-content-center align-items-center flex-column px-3">
          <StockLogo color={pathname.includes("stock") ? "white" : "rgba(5, 27, 53, 1)"}  size={windowWidth > 1440 ? 40 : 25}/>
          <div id='border' className='position-absolute top-0'/>
        <h3 className={`${pathname.includes("stock") ? "text-white" : ""} mt-2 text-center viewheader-item-title`} >{"Stok Takibi"}</h3>
        </div>
      <div className={` position-absolute `} style={{backgroundColor:"black",height:"60%",width:"1px",right:"-10px"}}></div>
      </NavLink>
      <NavLink id='link' to={`/price`} className="d-flex viewerheader-left-item justify-content-center align-items-center position-relative">
        <div className="d-flex justify-content-center align-items-center flex-column px-3">
          <PriceLogo color={pathname.includes("price") ? "white" : "rgba(5, 27, 53, 1)"}  size={windowWidth > 1440 ? 40 : 25}/>
          <div id='border' className='position-absolute top-0'/>
        <h3 className={`${pathname.includes("price") ? "text-white" : ""} mt-2 text-center viewheader-item-title`} >{"Fiyat Takibi"}</h3>
        </div>
      </NavLink>
    </>
 
  )
}