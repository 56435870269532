import React from 'react'
import  {Button} from "react-bootstrap"
import DownIcon from "../Icons/DownIcon"

export default function ModalCloseComponent({handleClose}) {
  return (
    <Button onClick={handleClose} className='position-absolute border-0 fs-3 m-0 p-0' style={{top:"-20px",left:"-20px",backgroundColor:"transparent"}}>
        <DownIcon size={45}/>
    </Button> 
  )
}
