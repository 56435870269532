import React from 'react'
import {Form} from "react-bootstrap"
import ModalSliderComponent from "./ModalSliderComponent"
export default function ModalPhotoDisplayComponent({label,customClass,images,staticImages}) {
  return (
    <Form.Group className="mb-2 ">
        <Form.Label>{label}</Form.Label>
          <div className='position-relative' style={{margin:"0rem 5rem"}}>
              <ModalSliderComponent slidesPerView={4} size={40} customClass={customClass} images={images} staticImages={staticImages} littlePicture={true}></ModalSliderComponent>
          </div>
    </Form.Group>
  )
}
