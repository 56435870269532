import {Col} from 'react-bootstrap';
import HeaderItemComponent from './HeaderItemComponent';
export default function ViewerHeaderLeftComponent() {

  return (
    <Col xxl={7} xl={7} lg={7} className='d-flex  h-100 gap-3 justify-content-start position-relative'>
        <HeaderItemComponent/>
    </Col>
  )
}
