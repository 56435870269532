import {Col } from 'react-bootstrap';
import { useLocation,useParams,useHistory } from 'react-router-dom';
import DownIcon from '../Icons/DownIcon';
import {  useState } from 'react';
import { useEffect } from 'react';
import config from '../../services/config'
import { Image } from 'react-bootstrap'
import DynamicComponent from './DynamicComponent';

function CardComponent({data,cardClick,shadow,description,topInfo,bottomInfo,logo}) {
  
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const {id} = useParams();
  const home = pathname ==="/"
  const stock = pathname.includes("/stock")
  const price = pathname.includes("/price")
  const detailPages = id?true:false;
  const [textColor,setTextColor] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);

  useEffect(() => {
    if (home) {
      setTextColor("white");
    } else {
      setTextColor("rgba(0, 67, 140, 1)");
    }
  }, [home]);

  const handleClick = (event)=>{
    if (home) {
      history.push(data?.pathName)
    }
    else if(cardClick){
      cardClick(data?.id,data?.errorMessage)
    }else
    {
      event.preventDefault();
    }
  }


  return (
    
    <Col xxl={data?.col ? data?.col : 12 } xl={data?.col ? data?.col : 12} lg={data?.col ? data?.col : 12} md={data?.col ? data?.col : 12} sm={data?.col ? data?.col : 12} className={` ${data?.pathName}`} style={{cursor:"pointer"}}>
      <div onClick={handleClick} id='card' className={`row rounded-4 text-light position-relative overflow-hidden h-100 shadow`} style={{ backgroundColor: data?.color ? data.color : "white" }}>
        <div className={`position-absolute w-100 h-100 right-0 z-0 ${shadow}`}/>
        <Col lg={9} xl={9} xxl={9} className={`z-1 card-content d-flex flex-column justify-content-between`}>

          {(data?.logo && data?.logo !=="00000000-0000-0000-0000-000000000000") ?  
          <Image src={`${config.API_URL}/file/${data?.logo}`} alt={data?.name} width={windowWidth > 1680 ? 130 : windowWidth < 1380 ? 35 :  70} height={windowWidth > 1680 ? 100 : windowWidth < 1380 ? 35 :  50} className=' mt-3 mb-2 card-logo'/>
          :
          <div className='pt-3 mt-1 mb-2 card-logo'>
            <DynamicComponent logo={logo} color={data?.logoColor}/>
          </div>
          }
          {topInfo && <span className={`${(stock || price) && "top-right-info-bg fs-6 p-1 rounded px-3 text-white fw-bold"} position-absolute fw-medium mt-2 fs-5 text-end top-right-info-color text-capitalize`} style={{display:"inline-block",width:"fit-content",right:"1rem",top:"1rem",fontWeight:"500"}}>{topInfo}</span>}
          <div>
            <h1 id='link' className='card-title ' style={{color:textColor,fontSize:"2.5rem",fontWeight:"800"}}>{`${detailPages ? "Tezgah " : ""} ${detailPages ? data?.marketStallName : data?.name ? data?.name : data?.fullName?.toLocaleLowerCase("tr-TR")}`}</h1>
            <p className='fw-medium mt-2 card-description w-100 text-capitalize' style={{color:textColor,fontSize:"1rem",fontWeight:"500"}}>{description}</p>
          </div>
        </Col>
        { detailPages !==true &&
          <Col lg={3} xl={3} xxl={3} className='d-flex justify-content-end align-items-end mb-5 px-4 z-0  next-button'>
            <div style={{transform:"rotate(180deg)",zIndex:"20"}}> <DownIcon size={windowWidth > 1440 ? 50 :  30} color={`${home ? (data?.pathName === "stand" ?  "rgba(91, 169, 54, 1)" : "white"): "rgba(0, 67, 140, 1)"}`}></DownIcon></div>
          </Col>
        }
        {bottomInfo && <span className={`position-absolute fw-bold mb-2 fs-6 text-end text-capitalize`} style={{right:"1rem",bottom:"1rem",color:"rgba(0, 67, 140, 1)"}}>{bottomInfo}</span>}
      </div>
    </Col>
  )
}
export default CardComponent;