import ModalHeaderComponent from '../Modals/ModalHeaderComponent';
import { Form, Modal} from 'react-bootstrap';
import ModalCloseComponent from '../Modals/ModalCloseComponent';
import ModalInputComponent from '../Modals/ModalInputComponent';
import { useProductsState } from '../../store/accessors';

export default function ProductItemModalComponent({showModal,setShowModal,selectedId}) {
    const handleClose = () => setShowModal(false);

const productsInfo=useProductsState(selectedId);

  return (
    <div>
     <Modal id="product-info-modal" show={showModal} onHide={handleClose} className='custom-dialog ' size="lg" style={{boxShadow:"none",backgroundColor:"rgba(0, 67, 140,0.5)",margin:"0px !important"}}>
     <div className='p-0 m-0 mx-4'><ModalHeaderComponent title={productsInfo?.name} logo={"Products"} img={productsInfo?.logo}/></div>
      <ModalCloseComponent handleClose={handleClose}/>
    <Modal.Body className='position-relative m-4 mt-0'>
          <div className='position-absolute p-0 pe-4 m-0 w-100 d-flex justify-content-center align-items-center' style={{height:"1px",top:"0px"}}>
            <div className='bg-black h-100 y-line w-100' style={{width:"98%"}}></div>
          </div>
        <ModalInputComponent label={"Minumum - Maximum Fiyat"} placeholder={`${productsInfo?.minPrice && productsInfo?.maxPrice ?  (`Min: ${parseFloat(productsInfo?.minPrice).toFixed(2)} ₺ - Max: ${parseFloat(productsInfo?.maxPrice).toFixed(2)} ₺` ) : ""}`}/>
        <Form.Group className="mb-2">
          <Form.Label>Faydalı Bilgiler</Form.Label>
          <Form.Control as="textarea" style={{resize: 'none'}} rows={10} placeholder={productsInfo?.useFulInfo} disabled className='p-3'/>
        </Form.Group>
    </Modal.Body>
    </Modal>
    </div>
  )
}
