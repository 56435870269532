export default function MarketSettlementLogo({size,color}) {
  return (
      <svg width={size} height={size} viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.8799 16.5866V34.7564" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.21045 34.7563V16.5854" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M49.7171 1H60.1961C60.7361 1 61.1744 1.43829 61.1744 1.97829V3.69029C61.1744 4.2315 60.7361 4.66858 60.1961 4.66858H1.97829C1.43708 4.66858 1 4.2315 1 3.69029V1.97829C1 1.43829 1.43708 1 1.97829 1H45.4794" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.56836 57.5052V38.4256" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M58.606 38.4256V57.5052" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.4871 34.7557H60.1961C60.7361 34.7557 61.1744 35.194 61.1744 35.7352V37.4472C61.1744 37.9872 60.7361 38.4255 60.1961 38.4255H1.97829C1.43708 38.4255 1 37.9872 1 37.4472V35.7352C1 35.194 1.43708 34.7557 1.97829 34.7557H16.2494" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50.682 61.1744H60.1961C60.7361 61.1744 61.1744 60.7361 61.1744 60.1962V58.4841C61.1744 57.9429 60.7361 57.5059 60.1961 57.5059H1.97829C1.43708 57.5059 1 57.9429 1 58.4841V60.1962C1 60.7361 1.43708 61.1744 1.97829 61.1744H46.4444" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M36.5901 11.396V4.6692H25.5825V11.396C25.5825 14.4356 28.0466 16.8997 31.0862 16.8997C34.126 16.8997 36.5901 14.4356 36.5901 11.396Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M47.5979 11.396V4.6692H36.5903V11.396C36.5903 14.4356 39.0545 16.8997 42.0941 16.8997C45.1338 16.8997 47.5979 14.4356 47.5979 11.396Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M58.6052 11.396V4.6692H47.5977V11.396C47.5977 14.4356 50.0618 16.8997 53.1014 16.8997C56.1411 16.8997 58.6052 14.4356 58.6052 11.396Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.5623 11.396V4.6692H3.55469V11.396C3.55469 14.4356 6.01881 16.8997 9.05841 16.8997C12.0981 16.8997 14.5623 14.4356 14.5623 11.396Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.5701 11.396V4.6692H14.5625V11.396C14.5625 14.4356 17.0266 16.8997 20.0662 16.8997C23.1059 16.8997 25.5701 14.4356 25.5701 11.396Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M51.2676 34.7564V16.5866" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M54.9365 16.5854V34.7563" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M37.2018 51.6346H24.9712C24.2957 51.6346 23.748 51.087 23.748 50.4115V45.5193C23.748 44.8438 24.2957 44.2962 24.9712 44.2962H37.2017C37.8772 44.2962 38.4248 44.8438 38.4248 45.5193V50.4115C38.4248 51.0871 37.8773 51.6346 37.2018 51.6346Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
}
