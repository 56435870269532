
import {Row, Col } from 'react-bootstrap';
import { useEffect, useState } from "react";
export default function MainHeaderComponent({children}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);
  return (
    <>
      <header className="row d-flex justify-between align-items-center main-header">
        <Row className="col-6 justify-center">
          <Col xs={2} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'><img xs={2} md={2} lg={2} xl={2} xxl={2} width={windowWidth<1025?45:70} height={windowWidth<1025?45:70} className='' src={"assets/logos/bakanlik.svg"} alt="Sanayi Bakanlığı"></img></Col>
          <Col xs={2} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'><img xs={2} md={2} lg={2} xl={2} xxl={2} width={windowWidth<1025?55:85} height={windowWidth<1025?55:85} className='' src={"assets/logos/kalkinmaAjansiLogo.svg"} alt="Kalkınma Ajansı"></img></Col>
          <Col xs={2} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'><img xs={2} md={2} lg={2} xl={2} xxl={2} width={windowWidth<1025?45:70} height={windowWidth<1025?45:70} className='' src={"assets/logos/ibb.svg"} alt="IBB"></img></Col>
          <Col xs={2} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'><img xs={2} md={2} lg={2} xl={2} xxl={2} width={windowWidth<1025?45:70} height={windowWidth<1025?45:70} src={"assets/logos/IstanbulUniLogo.svg"} alt="Istanbul Universitesi"></img></Col>
          <Col xs={2} md={2} lg={2} xl={2} xxl={2} className='d-flex align-items-center'><img xs={2} md={2} lg={2} xl={2} xxl={2} width={windowWidth<1025?45:70} height={windowWidth<1025?45:70} src={"assets/logos/topraktan-fileye-logo.svg"} alt="Topraktan Fileye"></img></Col>
        </Row>
      </header>
      {children}
    </>
  )
}
