import React, { useState } from 'react'
import {Form} from 'react-bootstrap';
import PdfsLogoComponent from '../Icons/PdfsLogoComponent';
import PdfViewerComponent from '../Shared/PdfViewerComponent';
export default function DocumentsComponent({label,data,staticDocuments}) {

  const [showModal,setShowModal] = useState(null);
  const [selectedDocuments,setSelectedDocuments]= useState("");
  return (
    <>
      <Form.Group >
          <Form.Label>{label}</Form.Label>
          <div className='d-flex gap-3' >
          {data ? data?.map((item,index) => (
             item?.extension === ".pdf" ?
         <div key={index} onClick={()=>setShowModal(true)}>
           <div onClick={()=>setSelectedDocuments(item?.id)}>
            <PdfsLogoComponent size={40} data={item} key={index}></PdfsLogoComponent>
          </div>
         </div>:null
          ))
          :
          staticDocuments && staticDocuments.map((item,index)=>(
            <div key={index} onClick={(event)=>event.preventDefault()}>
            <PdfsLogoComponent size={40} data={item} title={item} key={index}></PdfsLogoComponent>
          </div>
          ))
          }
          </div>
      </Form.Group>
          <PdfViewerComponent documentId={selectedDocuments} showModal={showModal} setShowModal={setShowModal}/>
    </>
  )
}