import React, { useRef } from 'react'
import DownIcon from '../../components/Icons/DownIcon'
import { useEffect } from 'react';
import { useState } from 'react';

export default function ScrollBarComponent({children}) {
  
    const containerRef = useRef(null);
    const scrollToBottom = () => {
        const container = containerRef.current;
        const scrollStep = -200; 
        container.scrollTop += scrollStep;
      };
    const scrollToTop = () => {
        const container = containerRef.current;
        const scrollStep = 200; 
        container.scrollTop += scrollStep;
      };
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);

      useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      },[]);
  return (
    <div className='w-100 p-0 position-relative'>
      <div className='scroll' ref={containerRef}>
      {children}
      <button onClick={scrollToBottom} className='position-absolute bg-transparent border-0 scroll-button-top'>
        <DownIcon size={windowWidth<1250?40:60}/>
      </button>
      <button onClick={scrollToTop} className='position-absolute bg-transparent  border-0 scroll-button-bottom'>
        <DownIcon size={windowWidth<1250?40:60}/>
      </button>
      </div>
    </div>
  )
}
