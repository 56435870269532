import { createSlice } from "@reduxjs/toolkit";

const marketInfoSlice=createSlice({
    name:"marketInfo",
    initialState:{
      // marketInfos:[{
      //   id:"absaasfl13or0hqo13n0q",
      //   name:"Kadıköy",
      //   description:"Kadıköy Üretici Pazarı"
      // }
      // ],
      marketInfos:[]
    },
    reducers:{
      setMarketInfos(state,actions){
          state.marketInfos=actions.payload;
      },
}})
export const {setMarketInfos}=marketInfoSlice.actions;
export default marketInfoSlice.reducer;