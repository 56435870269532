import './styles/App.css';
import './styles/Modal.css';
import './styles/Shared.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppRouter from './AppRouter';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DownloadIstanbulSeninComponent from './components/DownloadIstanbulSeninComponent';
import { handleResize, startTimer, handleInteraction, handleVideoEnded, initialize} from './services/utils';
import {myvids} from "../src/data/videos"

export default function App() {
  const [isSmallHeight, setIsSmallHeight] = useState(false);
  const [isSmallWidth, setIsSmallWidth] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const history = useHistory();
  const videoRef = useRef(null);


  let activeVideo = 0;

  useEffect(() => {
    let timer;

    const handleInteractionWrapper = () => handleInteraction(videoPlaying, setVideoPlaying, timer, clearTimeout, startTimer);
   
    startTimer(setVideoPlaying);
    window.addEventListener("click", handleInteractionWrapper);
    window.addEventListener("mousemove", handleInteractionWrapper);

    return () => {
      window.removeEventListener("click", handleInteractionWrapper);
      window.removeEventListener("mousemove", handleInteractionWrapper);
      clearTimeout(timer);
    };
  }, [videoPlaying, history]);

  useEffect(() => {
    console.log(myvids);
    const handleResizeWrapper = () => handleResize(setIsSmallHeight, setIsSmallWidth);
    handleResizeWrapper();
    window.addEventListener('resize', handleResizeWrapper);
    initialize();
    setInterval(() => {
      initialize();
    }, 15 * 60 * 6000);
    return () => {
      window.removeEventListener('resize', handleResizeWrapper);
    };
  }, []);

  if (isSmallWidth || isSmallHeight) {
    return (
      <DownloadIstanbulSeninComponent />
    );
  }
  const setActiveVideo=(active)=>activeVideo=active
  const handleVideoEndedWrapper = () => handleVideoEnded(activeVideo, myvids, videoRef,setActiveVideo);
  return (
    <>
      {videoPlaying ? (
        <div className="video-overlay m-0 p-0 overflow-hidden" style={{ width: "100vw", height: "100vh" }}>
          <video ref={videoRef} className='m-0 h-100 w-100' style={{ objectFit: "cover", background: "black" }} muted autoPlay onEnded={handleVideoEndedWrapper}>
            <source src={myvids[activeVideo]} type="video/mp4" />
          </video>
        </div>
      ) : (
        <div className="position-absolute top-0 bottom-0 w-100 bg-white bg-opacity-50 overflow-hidden p-0 m-0" style={{ minWidth: "100vw" }}>
          <AppRouter />
        </div>
      )}
    </>
  );
}
