import React, { useEffect, useState } from 'react'
import { Col, Dropdown, Form } from 'react-bootstrap'
import SortLogo from '../Icons/SortLogo'
import FilterLogo from '../Icons/FilterLogo'

export default function CustomizedComponent({children,click,filterClick,filterItems}) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  },[]);
  
  const handleCheckboxChange = (event,item)=>{
    const isChecked = event.target.checked;
    item.selected=isChecked;
    filterClick(item);
  }


  return (
    <Col xxl={11} xl={11} lg={11} className='my-2 d-flex justify-content-between align-items-center ps-4 w-100 pe-5'>
      <Dropdown className='rounded-3 me-2'>
      <Dropdown.Toggle id="dropdown-basic" className='px-4 bg-white d-flex justify-content-center align-items-center position-relative bg-white border-0' >
            <FilterLogo size={windowWidth<1250 ? 20 : 30}/>
            <div className='position-absolute filter-button-brace'/>
            <p className='filter-button-p'>Filtrele</p>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {filterItems?.map((item,index)=>(
             <Form.Check
             key={index}
             className='ms-3 ps-3'
             type={'checkbox'}
             id={`${item?.id}`}
             label={`${item?.name}`}
             onChange={(event) => handleCheckboxChange(event, item)}
           />
        ))}
      </Dropdown.Menu>
    </Dropdown>
    {children}
    <Dropdown className='rounded-3 ms-2 pe-5 sort-dropdown'>
      <Dropdown.Toggle id="dropdown-basic" className='px-4 bg-white d-flex justify-content-center align-items-center position-relative bg-white border-0' >
            <SortLogo size={windowWidth<1250 ? 20 : 30}/>
            <div className='position-absolute filter-button-brace'></div>
            <p className='filter-button-p'>Sırala</p>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={()=>click(true)} className='fw-bolder fs-6 px-4'>Artan</Dropdown.Item>
        <Dropdown.Item onClick={()=>click(false)} className='fw-bolder fs-6 px-4'>Azalan</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    </Col>
  )
}
