import { api } from "../api";
import { store } from "../../store";
import { setProducts } from "../../store/slices/productSlice";

const ENDPOINT="products";
const dispatch=store.dispatch;
export const  getProducts= async()=>{
    return api.get(`/${ENDPOINT}`).then(({data})=>{
        dispatch(setProducts(data.data));
        return true;
    }).catch((err)=>{
        return err.message;
    })
}