/* eslint-disable react-hooks/exhaustive-deps */
import CustomizedComponent from '../components/Shared/CustomizedComponent'
import ScrollBarComponent from '../components/Shared/ScrollBarComponent'
import CardComponent from '../components/Shared/CardComponent'

import { Col, Row } from 'react-bootstrap'
import { useProductsState } from '../store/accessors'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { searchFilter, sortData } from '../services/utils'
import { useState } from 'react'
import { useEffect } from 'react'
import { getProducts } from '../services/apis/product'
import { useHistory } from 'react-router-dom'
import DownIcon from '../components/Icons/DownIcon'

export default function PriceDetailPage() {

  const {id} = useParams();
  const price = useProductsState(id);

    useEffect(async() => {
      if (price) {
        setLoading(false);
      } else {
        var result = await getProducts();
        setLoading(result && false );
      }
      }, []);
  
      const [loading,setLoading]=useState(true);

    const searchValue = useSelector((state) => state.search.value);
    const product = price?.name;
    const [filterItems,setFilterItems]=useState([
        {
          id:"5656156156154544",
          name:"20 ₺ veya daha fazla olanlar",
          price:20,
          selected:false
        },
        {
          id:"4629486315894986",
          name:"40 ₺ veya daha fazla olanlar",
          price:40,
          selected:false
        },
      ]);

  const [filterList,setFilterList]=useState(price?.theStalls);

    const sortClick = (desc) => {
        const sortedStalls = sortData(price?.theStalls, 'price',desc);
        setFilterList(sortedStalls);
      };

      const filterClick = (item)=>{
        const itemList=filterItems;
        const findItem=itemList.find(x=>x.id===item.id);
        findItem.id=item.id;
        setFilterItems(itemList);
        setFilterList(filteredPrice(price?.theStalls,itemList))
        
      }
    
      const filteredPrice = (theStalls, items) => {
      const selectedItems = items.filter((x) => x.selected === true);
      const filteredPrice = theStalls.filter((theStalls) => {
      return selectedItems.every((selectedItem) => {
        return parseInt(theStalls?.price) >= selectedItem?.price;
      });
    });
    
    return filteredPrice;
    };

    useEffect(() => {
      setFilterList(price?.theStalls);
    }, [price]);
      
    const history = useHistory();
    return (
       <>
       {
        filterList?.length<1 ? <><button onClick={()=>{history.goBack()}} className='m-4 p-0 border-0 bg-transparent'><DownIcon size={40}/></button><div className='fs-1 w-100 h-75 d-flex justify-content-center align-items-center'>Tezgah Bilgisi Bulunamadı...</div></> : 
         <div className='px-4' style={{overflow:"hidden"}} >
         <Row className='w-100'>
                 <div className='w-100 p-0 d-flex'>
                 <button onClick={()=>{history.goBack()}} className='m-0 p-0 border-0 bg-transparent'><DownIcon size={40}/></button>
                     <CustomizedComponent filterClick={filterClick} filterItems={filterItems} click={sortClick}/>
                 </div>
             <ScrollBarComponent>
                 <Row className='gx-5 px-2 ms-1 gy-4 py-4 pe-0' style={{width:"95%"}}>
                    
                     <>
                  {loading ? <div className="fs-1 w-100 d-flex justify-content-center align-items-center" style={{height:"50vh"}}>Yükleniyor...</div> : <>   {filterList?.filter((item) => searchFilter(item,searchValue)).map((item, index) => ( 
                         <Col key={index} xxl={4} xl={4} lg={4} className='m-0 mb-3 mt-2 card-container'>
                             <Row className='h-100 pages-card-container'>
                                 <CardComponent logo={"Products"} description={product} shadow={"price-page-shadow"} topInfo={`${(parseFloat(item?.price).toFixed(2) + " ₺")}`} bottomInfo={`${item?.stock + (item?.stockUnit ==="Kilogram"?" Kg": " "+ item?.stockUnit)}`} data={item}/>
                             </Row>
                         </Col>
                     ))}  </> }
                  </>
                 </Row>
             </ScrollBarComponent>
         </Row>
     </div>
       }
       </>
  )
}
