// import React, { useState } from 'react'
import { useState } from 'react';
import ModalComponent from './ModalComponent';
import ModalCloseComponent from './ModalCloseComponent';
import { Form, Button, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import { api } from '../../services/api';
import * as Yup from 'yup';

export default function ModalSendMessageComponent({
  showModal,
  handleClose,
  farmerId,
  marketStallId,
}) {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);


  const initialValues = {
    farmerId,
    marketStallId,
    content: '',
    fromEmail:'test@indata.com.tr'
  };
  const validationSchema = Yup.object({
    content: Yup.string()
      .required('Mesaj alanı zorunludur')
      .min(3, 'En az 3 karakter girmelisiniz'),
  });

  async function onSubmit(values, { resetForm }) {
    try {
      await api.post('/comments', { ...values });
      resetForm();
      setSuccessMessage('Mesajınız gönderildi.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 2000);
    } catch (error) {
      values.content = '';
      setErrorMessage('Bir hata oluştu daha sonra tekrar deneyiniz.');
      setTimeout(() => {
        setErrorMessage('');
      }, 2000);
      console.error('Mesaj gönderilemedi:', error);
    }
  }

  return (
    <section>
      <ModalComponent showModal={showModal} handleClose={handleClose} className="bg-danger p-4 m-4 ">
        <ModalCloseComponent handleClose={handleClose}></ModalCloseComponent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, handleChange, errors, setFieldValue }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mx-5 mt-5 mb-4">
                <Form.Control id='send-message' style={{ resize: "none" }} as="textarea" rows={8} placeholder='Mesajınızı Yazınız...' name='content' value={values.content} onChange={handleChange} className='p-3 ' />
              </Form.Group>
              {errors.content && <Alert className='position-absolute ms-5 bg-danger text-white' >
                {errors.content}
              </Alert>}
              {errorMessage && <Alert className='position-absolute ms-5 bg-danger text-white' >
                {errorMessage}
              </Alert>}
              {successMessage && <Alert className='position-absolute ms-5 bg-success text-white' >
                {successMessage}
              </Alert>}
              <div className='d-flex pe-5  mb-5 justify-content-end align-items-start'>
                <Button type="submit" className='fw-bold fs-3 border-0 modal-button' style={{ padding: ".5rem 5rem" }}>
                  Gönder
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalComponent>
    </section>
  )
}
