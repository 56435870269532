import React from 'react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { render } from 'react-dom';

const root = document.getElementById('root');
render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
     </Provider>
  </React.StrictMode>,
  root
);
reportWebVitals();


