import { api } from "../api";
import { store } from "../../store";
import { setFarmers } from "../../store/slices/farmerSlice";

const ENDPOINT="farmers";
const dispatch=store.dispatch;
export const  getFarmers=async()=>{
 return await api.get(`/${ENDPOINT}`).then(({data})=>{
        dispatch(setFarmers(data.data));
        return true;
    }).catch((err)=>{
      return err.message;
    })
}