import { api } from "../api";
import { store } from "../../store";
import { setStands } from "../../store/slices/standSlice";

const ENDPOINT="market-stalls";
const dispatch=store.dispatch;
export const  getStands= async()=>{
 return await api.get(`/${ENDPOINT}`).then(({data})=>{
        dispatch(setStands(data.data));
        return true
    }).catch((err)=>{return err.message;})
}