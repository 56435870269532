import { getFarmers } from "../services/apis/farmer";
import { getProducts } from "../services/apis/product";
import { getStands } from "../services/apis/stand";
import { getMarketInfos } from "../services/apis/marketInfo";
import { getBadges } from "./apis/badges";

const searchFilter = (item, searchValue) => {
  for (let key in item) {
    if (typeof item[key] === 'object') {
      const result = searchFilter(item[key], searchValue);
      if (result) {
        return true;
      }
    } else if (item[key] !== null && item[key].toString().toLowerCase().includes(searchValue.toLowerCase())) {
      return true;
    }
  }
  return false;
};
const sortData = (item, prop, desc = true) => {
  const data = [...item];
  return data.sort((a, b) => {
    const valueA = String(a[prop] && a[prop]).toLowerCase();
    const valueB = String(b[prop] && b[prop]).toLowerCase();
    const result = valueA.localeCompare(valueB, undefined, { numeric: true });
    return desc ? result : -result;
  });
};



 function initialize() {
  getFarmers();
  getProducts();
  getStands();
  getMarketInfos();
  getBadges();
}


function startTimer(setVideoPlaying) {
  return setTimeout(() => {
    if (setVideoPlaying !== undefined) {
      setVideoPlaying(true);
    }
  }, 15*60000);
}
function handleResize(setIsSmallHeight, setIsSmallWidth) {
  setIsSmallHeight(window.innerHeight <= 499);
  setIsSmallWidth(window.innerWidth <= 1023);
}


function handleInteraction(videoPlaying, setVideoPlaying, timer, clearTimeout, startTimer) {
  if (videoPlaying) {
    setVideoPlaying(false);
    clearTimeout(timer);
    startTimer();
  }
}

function handleVideoEnded(activeVideo, myvids, videoRef, setActiveVideo) {

  const nextVideoIndex = (activeVideo + 1);
  activeVideo = ((myvids.length)===nextVideoIndex)?0:nextVideoIndex;
  videoRef.current.src = myvids[activeVideo];
  videoRef.current.play();
  setActiveVideo(activeVideo);
}

export { searchFilter, sortData,  handleResize, startTimer, handleInteraction, handleVideoEnded, initialize };
