import React from 'react';
import {Route} from 'react-router-dom';
import FarmerPage from './pages/FarmerPage';
import MarketInfoPage from './pages/MarketInfoPage';
import PricePage from './pages/PricePage';
import ProductsPage from './pages/ProductsPage';
import StockPage from './pages/StockPage';
import PriceDetailPage from './pages/PriceDetailPage';
import StockDetailPage from './pages/StockDetailPage';
import MarketSettlementPage from './pages/MarketSettlementPage';
import HomePage from './pages/HomePage';
import ViewerLayout from './Layouts/ViewerLayout';
import MainLayout from './Layouts/MainLayout';
import NotFoundPage from './pages/NotFoundPage';
import { Switch } from 'react-router-dom';

export default function AppRouter() {
  return (
    <>
        <Route path="/" exact>
          <MainLayout>
            <HomePage/>
          </MainLayout>
        </Route>
        <Route>
          <Switch>
          <ViewerLayout>
            <Route path="/farmer" component={FarmerPage} />
            <Route path="/market-info" component={MarketInfoPage} />
            <Route path="/stand" component={MarketSettlementPage} />
            <Route path="/products" component={ProductsPage} />
            <Route exact path="/stock" component={StockPage} />
            <Route path="/stock/:id" component={StockDetailPage} />
            <Route exact path="/price" component={PricePage} />
            <Route path="/price/:id" component={PriceDetailPage} />
            <Route path="*" component={NotFoundPage} />
          </ViewerLayout>
          </Switch>
        </Route>
    </> 
  );
}
