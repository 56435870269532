import { createSlice } from "@reduxjs/toolkit";

const farmerSlice=createSlice({
    name:"farmer",
    initialState:{
      // farmers:[
      //   {
      //     "id": "1f78ac30-1-4a7c-efe9-08db69c339e2",
      //     "badges": [
      //       "Kadın Çiftçi","Genç Çiftçi"
      //     ],
      //     "qrCode": "165169818965",
      //     "marketStallName": "1",
      //     "fullName": "erkan çalışkan",
      //     "description": null,
      //     "city": "ADANA/GÖLBAŞI/CUMHURİYET",
      //     "landName": "İstanbul / Şile - 5 Hektar",
      //     "cooperativeName": [
      //       "İstanbul Tarım Koop",
      //       "Ankara Tarım Koop"
      //     ],
      //     "educationAndCertificate": [
      //       "Erkan Çalışkan",
      //       "Deneme"
      //     ],
      //     "producedProducts": [
      //       {
      //         "id": "7b761946-640f-4d5e-75e1-08db69c30579",
      //         "name": "Acur",
      //         "product": [
      //           {
      //             "whereProduced": "Sülüntepe Mahallesi Abdurrahman Gazi Sokak",
      //             "harvestDate": "10.06.2023",
      //             "ProductImageList":null,
      //             "analysis": [
      //               {
      //                 "name": "Test",
      //                 "date": "4.06.2023 21:24:12",
      //                 "result": "var",
      //                 "explanation": "Burası Açıklama Alanıdır.",
      //                 "photos": null,
      //                 "documents": null
      //               },
      //               {
      //                 "name": "Test",
      //                 "date": "4.06.2023 21:24:12",
      //                 "result": "var",
      //                 "explanation": "Burası Açıklama Alanıdır.",
      //                 "photos": null,
      //                 "documents": null
      //               },
      //             ],
      //             "fertilizations": [                {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },
      //             {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },],
      //             "spraying": [                {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },
      //             {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },]
      //           }
      //         ]
      //       },
      //       {
      //         "id": "9249466e-881a-4abf-75e6-08db69c30579",
      //         "name": "Armut",
      //         "product": [
      //           {
      //             "whereProduced": "Sülüntepe Mahallesi Abdurrahman Gazi Sokak",
      //             "harvestDate": "10.06.2023",
      //             "analysis": [],
      //             "fertilizations": [],
      //             "spraying": []
      //           }
      //         ]
      //       }
      //     ],
      //     "documents": null,
      //     "whatDidIBrang": [
      //       "Acur",
      //       "Armut"
      //     ],
      //     "productionAreaPhotos": null,
      //     "nostalgicPhotos": null
      //   },
      //   {
      //     "id": "asd-1-4a7c-efe9-08db69c339e2",
      //     "badges": [
      //     ],
      //     "qrCode": "165169818965",
      //     "marketStallName": "2",
      //     "fullName": "test",
      //     "description": null,
      //     "city": "ADANA/GÖLBAŞI/CUMHURİYET",
      //     "landName": "İstanbul / Şile - 5 Hektar",
      //     "cooperativeName": [
      //       "İstanbul Tarım Koop",
      //       "Ankara Tarım Koop"
      //     ],
      //     "educationAndCertificate": [
      //       "Erkan Çalışkan",
      //       "Deneme"
      //     ],
      //     "producedProducts": [
      //       {
      //         "id": "7b761946-640f-4d5e-75e1-08db69c30579",
      //         "name": "Acur",
      //         "product": [
      //           {
      //             "whereProduced": "Sülüntepe Mahallesi Abdurrahman Gazi Sokak",
      //             "harvestDate": "10.06.2023",
      //             "analysis": [
      //               {
      //                 "name": "Test",
      //                 "date": "4.06.2023 21:24:12",
      //                 "result": "var",
      //                 "explanation": "Burası Açıklama Alanıdır.",
      //                 "photos": null,
      //                 "documents": null
      //               },
      //               {
      //                 "name": "Test",
      //                 "date": "4.06.2023 21:24:12",
      //                 "result": "var",
      //                 "explanation": "Burası Açıklama Alanıdır.",
      //                 "photos": null,
      //                 "documents": null
      //               },
      //             ],
      //             "fertilizations": [                {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },
      //             {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },],
      //             "spraying": [                {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },
      //             {
      //               "name": "Test",
      //               "date": "4.06.2023 21:24:12",
      //               "result": "var",
      //               "explanation": "Burası Açıklama Alanıdır.",
      //               "photos": null,
      //               "documents": null
      //             },]
      //           }
      //         ]
      //       },
      //       {
      //         "id": "9249466e-881a-4abf-75e6-08db69c30579",
      //         "name": "Armut",
      //         "product": [
      //           {
      //             "whereProduced": "Sülüntepe Mahallesi Abdurrahman Gazi Sokak",
      //             "harvestDate": "10.06.2023",
      //             "analysis": [],
      //             "fertilizations": [],
      //             "spraying": []
      //           }
      //         ]
      //       }
      //     ],
      //     "documents": [
      //       {
      //         "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //         "isActive": true,
      //         "createdOnUtc": "2023-06-19T12:29:57.816Z",
      //         "createUser": "string",
      //         "updatedOnUtc": "2023-06-19T12:29:57.816Z",
      //         "updateUser": "string",
      //         "filePath": "https://drive.google.com/file/d/1dVhoVw5K-BvtC3nBqr15oExWt2xYoJ3N/view?usp=sharing",
      //         "filename": "string",
      //         "description": "string",
      //         "extension": "string",
      //         "category": "string",
      //         "isLive": true
      //       },
      //       {
      //         "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      //         "isActive": true,
      //         "createdOnUtc": "2023-06-19T12:29:57.816Z",
      //         "createUser": "string",
      //         "updatedOnUtc": "2023-06-19T12:29:57.816Z",
      //         "updateUser": "string",
      //         "filePath": "https://drive.google.com/file/d/1dVhoVw5K-BvtC3nBqr15oExWt2xYoJ3N/view?usp=sharing",
      //         "filename": "string",
      //         "description": "string",
      //         "extension": "string",
      //         "category": "string",
      //         "isLive": true
      //       },
      //     ],
      //     "whatDidIBrang": [
      //       "Acur",
      //       "Armut"
      //     ],
      //     "productionAreaPhotos": null,
      //     "nostalgicPhotos": null
      //   },
      //   {
      //     "id": "2",
      //     "badges": [
      //       "Genç Çiftçi","ITU Sertifikalı Çiftçi","ITU Sertifikalı Çiftçi","ITU Sertifikalı Çiftçi",
      //     ],
      //     "qrCode": "11615662",
      //     "marketStallName": "3",
      //     "fullName": "ali candan",
      //     "description": null,
      //     "city": "ADANA/GÖLBAŞI/CUMHURİYET",
      //     "landName": null,
      //     "cooperativeName": [
      //       "İstanbul Tarım Koop",
      //       "Ankara Tarım Koop"
      //     ],
      //     "educationAndCertificate": [
      //       "Erkan Çalışkan",
      //       "Deneme"
      //     ],
      //     "producedProducts": [
      //       {
      //         "id": "7b761946-640f-4d5e-75e1-08db69c30579",
      //         "name": "Acur",
      //         "product": [
      //           {
      //             "whereProduced": "Sülüntepe Mahallesi Abdurrahman Gazi Sokak",
      //             "harvestDate": "10.06.2023",
      //             "analysis": [
      //               {
      //                 "name": "Test",
      //                 "date": "4.06.2023 21:24:12",
      //                 "result": "var",
      //                 "explanation": "Burası Açıklama Alanıdır.",
      //                 "photos": null,
      //                 "documents": null
      //               }
      //             ],
      //             "fertilizations": [],
      //             "spraying": []
      //           }
      //         ]
      //       },
      //       {
      //         "id": "9249466e-881a-4abf-75e6-08db69c30579",
      //         "name": "Armut",
      //         "product": [
      //           {
      //             "whereProduced": "Sülüntepe Mahallesi Abdurrahman Gazi Sokak",
      //             "harvestDate": "10.06.2023",
      //             "analysis": [],
      //             "fertilizations": [],
      //             "spraying": []
      //           }
      //         ]
      //       }
      //     ],
      //     "documents": null,
      //     "whatDidIBrang": [
      //       "Acur",
      //       "Armut"
      //     ],
      //     "productionAreaPhotos": null,
      //     "nostalgicPhotos": null
      //   },
     
      // ],
      farmers:[]
    },
    reducers:{
        setFarmers(state,actions){
            state.farmers=actions.payload;
     }
}})
export const {setFarmers}=farmerSlice.actions;
export default farmerSlice.reducer;