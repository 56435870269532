import { createSlice } from "@reduxjs/toolkit";

const badgeSlice=createSlice({
    name:"badge",
    initialState:{
      // badges:[
      //   {
      //     "componentName":"FarmerWomanRosette",
      //     "name":"Kadın Çiftçi"
      //   },
      //   {
      //     "componentName":"FarmerYoungRosette",
      //     "name":"Genç Çiftçi"
      //   },
      //   {
      //     "componentName":"FarmerWomensCooperativeRosette",
      //     "name":"Kadın Kooperatif Üyesi Çiftçi"
      //   },
      //   {
      //     "componentName":"FarmerLegalEntityRosette",
      //     "name":"Tüzel Kişilik (Kooperatif)"
      //   },
      //   {
      //     "componentName":"FarmerITURosette",
      //     "name":"İTU Sertf. Çiftçi"
      //   },
      //   {
      //     "componentName":"FarmerOrganicRosette",
      //     "name":"Organik Sertf. Çiftçi"
      //   },
      //  ]
      badges:[]
    },
    reducers:{
        setBadges(state,actions){
            state.badges=actions.payload;
     }
}})
export const {setBadges}=badgeSlice.actions;
export default badgeSlice.reducer;