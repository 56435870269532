import { createSlice } from "@reduxjs/toolkit";

const standSlice=createSlice({
    name:"stand",
    initialState:{
    // stands:[
    //   {
    //     "id":"1",
    //     "badges":["Erkek Çiftçi"],
    //     "qrCode":"16519898619",
    //     "marketStallName":"1",
    //     "fullName":"Erkan Çalışkan",
    //     "city":"İstanbul / Kadıköy - Hasanpaşa",
    //     "landName":"Şile - Ağva - 15 Dekar",
    //     "cooperativeName":"Tarım Kredi K. - Türkiye Tarım K.",
    //     "whatDidIBrang":[
    //       {
    //           "id":"ASAS-4SAF89ASF91CA-CWQ89S-SAC",
    //           "name":"domates",
    //           "productLogo":"tomato",
    //           "stock":"20",
    //           "unitPrice":"50",
    //           "whereProduced":"Şile, Ağva",
    //           "harvestDate":"02/10/2023",
    //           "analysis":[
    //             {
    //               "name":"Analiz 1",
    //               "date":"01/02/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":["asddasads","fasadsdas","asddasads"]
    //             },
    //             {
    //               "name":"Analiz 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "fertilizations":[
    //             {
    //               "name":"Gübreleme 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Gübreleme 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "spraying":[
    //             {
    //               "name":"İlaç 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"İlaç 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //       },
    //       {
    //           "id":"AS-FQ9W4-619AS1Q9W8-9CQ1-QW981AS",
    //           "name":"soğan",
    //           "productLogo":"onion",
    //           "stock":"50",
    //           "unitPrice":"30",
    //           "whereProduced":"Şile, Ağva",
    //           "harvestDate":"02/10/2023",
    //           "analysis":[
    //             {
    //               "name":"Analiz 1",
    //               "date":"01/02/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Analiz 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "fertilizations":[
    //             {
    //               "name":"Gübreleme 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Gübreleme 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "spraying":[
    //             {
    //               "name":"İlaç 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"İlaç 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //       },
    //       {
    //           "id":"tomatoId",
    //           "name":"domates",
    //           "productLogo":"tomato",
    //           "stock":"20",
    //           "unitPrice":"50",
    //           "whereProduced":"Şile, Ağva",
    //           "harvestDate":"02/10/2023",
    //           "analysis":[
    //             {
    //               "name":"Analiz 1",
    //               "date":"01/02/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Analiz 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "fertilizations":[
    //             {
    //               "name":"Gübreleme 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Gübreleme 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "spraying":[
    //             {
    //               "name":"İlaç 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"İlaç 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //       },
    //       {
    //           "id":"onionId",
    //           "name":"soğan",
    //           "productLogo":"onion",
    //           "stock":"50",
    //           "unitPrice":"30",
    //           "whereProduced":"Şile, Ağva",
    //           "productImageList":null,
    //           "harvestDate":"02/10/2023",
    //           "analysis":[
    //             {
    //               "name":"Analiz 1",
    //               "date":"01/02/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Analiz 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "fertilizations":[
    //             {
    //               "name":"Gübreleme 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Gübreleme 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "spraying":[
    //             {
    //               "name":"İlaç 1",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"İlaç 2",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //       },
    //     ],
    //     "producedProductsPhotos":null
    //   },
    //   {
    //     "id":"2",
    //     "badges":["Kadın Çiftçi"],
    //     "qrCode":"25252515126",
    //     "marketStallName":"2",
    //     "fullName":"Neriman Elif Korkmaz",
    //     "city":"İstanbul / Büyükçekmece - Dizdariye",
    //     "landName":"Çatalca / Düzyarı - 15 Dekar",
    //     "cooperativeName":"Tarım Kredi K. - Türkiye Tarım K.",
    //     "whatDidIBrang":[
    //       {
    //           "id":"onionId",
    //           "name":"soğan",
    //           "productLogo":"onion",
    //           "stock":"50",
    //           "unitPrice":"50",
    //           "whereProduced":"Dizdariye, Karataş, Mevlana",
    //           "harvestDate":"15/05/2023",
    //           "analysis":[
    //             {
    //               "name":"Analiz 3",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Analiz 4",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "fertilizations":[
    //             {
    //               "name":"Gübreleme 3",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"Gübreleme 4",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //           "spraying":[
    //             {
    //               "name":"İlaç 3",
    //               "date":"15/05/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //             {
    //               "name":"İlaç 4",
    //               "date":"20/10/2023",
    //               "result":"Analiz sonuçları",
    //               "explanation":"İşlem Açıklaması",
    //               "photos":null,
    //               "documents":null
    //             },
    //           ],
    //       },
    //     ],
    //     "producedProductsPhotos":null
    //   },

    // ]
    stands:[]
    },
    reducers:{
      setStands(state,actions){
          state.stands=actions.payload;
      },
    },
})
export const {setStands}=standSlice.actions;
export default standSlice.reducer;