import {Col} from 'react-bootstrap';
export default  function TitleComponent({title,subTitle,description}) {
  return (
<div className='home-title'>
<Col xxl={12} xl={12} lg={12} className=' h-100 d-flex align-items-end '>
<Col className='col-6 w-100 d-flex flex-column align-items-end'>
        <h1 className='title w-100 m-0 p-0 text-uppercase'>{title}</h1>
        <h2 className='subTitle w-100 m-0 p-0'>{subTitle}</h2>
        <p className='description w-100 m-0 p-0'>{description}</p>
    </Col>
</Col>
</div>
  )
}