import { api } from "../api";
import { store } from "../../store";
import { setBadges } from "../../store/slices/badgeSlice";

const ENDPOINT="badges";
const dispatch=store.dispatch;
export const  getBadges=async()=>{
 return await api.get(`/${ENDPOINT}`).then(({data})=>{
        dispatch(setBadges(data.data));
        return true;
    }).catch(()=>{return false})
}