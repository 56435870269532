import { useState } from 'react';
import {Modal,Button,Row,Col,Form} from 'react-bootstrap';
import ModalHeaderComponent from '../Modals/ModalHeaderComponent';
import ModalInputComponent from '../Modals/ModalInputComponent';
import DocumentsComponent from '../Modals/ModalDocumentsComponent';
import ModalPhotoDisplayComponent from '../Modals/ModalPhotoDisplayComponent';
import ModalButtonGroupComponent from '../Modals/ModalButtonGroupComponent';
import ModalCloseComponent from '../Modals/ModalCloseComponent';
import ModalComponent from '../Modals/ModalComponent';
import ModalSendMessageComponent from '../Modals/ModalSendMessageComponent';
import ModalProductsDetailComponent from './ModalProductsDetailComponent'
import { useFarmersState } from "../../store/accessors";

export default function FarmerDetailModalComponent({showModal,setShowModal,selectedId}) {
  
   
    const farmersInfo=useFarmersState(selectedId);
    const [showModalSendMessage,setShowModalSendMessage] = useState(false);
    const [showProductsModal,setShowProductsModal] = useState(false); 
    const [selectedProduct,setSelectedProduct] = useState(0);
    const handleClose = () => setShowModal(false);
    const handleCloseSendMessage=()=> setShowModalSendMessage(false);
    const containsNumber = /\d/.test(farmersInfo?.landName);

    const productionsClick = (id,index)=>{
       setSelectedProduct(parseInt(index));
        setShowProductsModal(true);
    }
   
    return (
    <>
          <ModalComponent showModal={showModal} handleClose={handleClose}  backgroundColor="rgba(0, 67, 140,0.5)">
               <ModalHeaderComponent title={farmersInfo?.fullName} subTitle={farmersInfo.badges && farmersInfo?.badges.join(",  ")} logo={"Farmer"} qrCode={farmersInfo?.qrCode} standNumber={farmersInfo?.marketStallName}></ModalHeaderComponent>
                 <ModalCloseComponent handleClose={handleClose}></ModalCloseComponent>

                <Modal.Body className='px-4' >
                    <Row className='position-relative h-100'>
                        <Col xxl={6} xl={6} lg={6}>
                        <Form>
                            <ModalInputComponent label={"Yaşadığım İl/İlçe Mahalle"} placeholder={farmersInfo?.city} />
                            <ModalInputComponent label={"Tarım Arazimin Yüzölçümü"} placeholder={farmersInfo?.landName?.length>0&&farmersInfo?.landName + (containsNumber ? " Dekar":"")} />
                            <ModalInputComponent label={"Bağlı Bulunduğum Kooperatifler"} placeholder={farmersInfo?.cooperativeName?.join(", ")} />
                            <ModalInputComponent label={"Katıldığım Eğitimler ve Sertifikalarım"} placeholder={farmersInfo?.educationAndCertificate?.join(", ")} />
                            {farmersInfo?.producedProducts?.length>0 &&  <ModalButtonGroupComponent click={productionsClick} label={"Ürettiğim Ürünler"} data={farmersInfo?.producedProducts}/>}
                            <div className='overflow-y-auto'>{farmersInfo?.documents?.filter(file => file?.extension === '.pdf').length > 0  && <DocumentsComponent label={"Ekler"} data={farmersInfo?.documents} title={''} />}</div>
                        </Form>
                        </Col>

                            <div className='position-absolute bg-black h-100 p-0 m-0 x-line' style={{width:"1px",left:"50%"}}/>
                            <div className='position-absolute p-0 m-0  w-100 d-flex justify-content-center align-items-center' style={{height:"1px",top:"-1%"}}>
                                <div className='bg-black h-100 y-line' style={{width:"98%"}}/>
                            </div>
                        
                        <Col xxl={6} xl={6} lg={6}>
                            <Form className='h-100 d-flex flex-column justify-content-between'>
                                <div>
                                <ModalInputComponent label={"Bugün Size Neler Getirdim ?"} placeholder={farmersInfo?.whatDidIBrang?.join(", ")} color={"text-green"}/>
                                {farmersInfo?.productionAreaPhotos?.filter(file => file?.extension === '.jpg' || '.png' || 'jpeg').length > 0  &&   <ModalPhotoDisplayComponent label={"Üretim Alanından Fotoğraflar"} customClass="product-area" images={farmersInfo?.productionAreaPhotos}/>}
                                {farmersInfo?.nostalgicPhotos?.filter(file => file?.extension === '.jpg' || '.png' || 'jpeg').length > 0  &&<ModalPhotoDisplayComponent label={"Nostaljik Fotoğraflar"} customClass="nostalgic-photo" images={farmersInfo?.nostalgicPhotos} />}
                                </div>
                                <div className='w-100 h-100 d-flex pe-5 justify-content-end align-items-end'>
                                   {
                                    farmersInfo?.marketStallId &&
                                     <Button onClick={() => setShowModalSendMessage(true)} className='fw-bold fs-3 border-0 p-2 mt-4 px-4 modal-button'>
                                         Çiftçiye Mesaj İlet
                                     </Button>
                                   }
                                   
                                    <ModalSendMessageComponent farmerId={farmersInfo?.id} marketStallId={farmersInfo?.marketStallId} showModal={showModalSendMessage} handleClose={handleCloseSendMessage}/>
                                </div>  
                            </Form>
                        </Col>
                    </Row>
               </Modal.Body>
            </ModalComponent>
         <ModalProductsDetailComponent marketStallId={farmersInfo?.marketStallId} farmer={farmersInfo} standNumber={farmersInfo?.marketStallName} qrCode={farmersInfo?.qrCode} data={farmersInfo?.producedProducts && farmersInfo?.producedProducts[selectedProduct]} showModal={showProductsModal} setShowModal={setShowProductsModal}/> 
    </>
  )
}
