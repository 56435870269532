export default function FarmerLogo({size,color}) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.588 40.3069L38.6321 40.349C43.1646 40.4115 46.807 44.1035 46.807 48.6373V62.8533C46.807 63.6988 46.1221 64.3837 45.2766 64.3837H2.53038C1.68485 64.3837 1 63.6988 1 62.8533V48.6373C1 44.1035 4.64231 40.4115 9.1748 40.349L12.219 40.3069" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.5508 34.295V38.0802C29.5508 39.2688 30.5047 40.2355 31.6921 40.2521L31.7622 40.2534" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.2563 34.295V38.0802C18.2563 39.2688 17.3024 40.2355 16.1151 40.2521L16.0449 40.2534" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M35.7334 16.8371H36.5713C38.4677 16.8371 40.0045 18.3739 40.0045 20.2703C40.0045 21.2192 39.6206 22.0762 38.9995 22.6985C38.3784 23.3196 37.5189 23.7035 36.5713 23.7035H35.7347" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0725 23.7035H11.2359C9.3395 23.7035 7.80273 22.1667 7.80273 20.2703C7.80273 19.3215 8.18661 18.4645 8.80769 17.8421C9.42877 17.221 10.2883 16.8371 11.2359 16.8371H12.0738" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M35.7334 16.8371C35.7346 16.8665 35.7346 16.8971 35.7346 16.9264V23.8961C35.7346 30.4296 30.4369 35.726 23.9035 35.726C17.37 35.726 12.0723 30.4296 12.0723 23.8961V16.9264C12.0723 16.8971 12.0723 16.8665 12.0735 16.8371" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.6821 12.6287L12.6066 4.5597C12.8068 3.72181 13.4152 3.03824 14.2275 2.75001C20.9217 0.377907 27.3723 0.458253 33.6061 2.74236C34.4045 3.03441 34.9975 3.71416 35.1952 4.54057L37.1247 12.6287" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M46.7205 56.9244H37.0229" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.784 56.9244H1.08643" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.4878 16.8372H41.1416C41.352 16.8372 41.5433 16.7518 41.6824 16.6128C41.8214 16.475 41.9068 16.2837 41.9068 16.072C41.9068 14.1705 40.3649 12.6287 38.4634 12.6287H9.34278C8.39266 12.6287 7.53055 13.0138 6.90819 13.6374C6.28456 14.2611 5.89941 15.1206 5.89941 16.072C5.89941 16.4942 6.24248 16.8372 6.66461 16.8372H16.0242" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.6353 8.63435H36.1141" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.2192 49.4637V39.3861C12.2192 38.964 12.5623 38.6209 12.9844 38.6209H15.28C15.7034 38.6209 16.0452 38.964 16.0452 39.3861V49.4637" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M31.7617 49.4637V39.3861C31.7617 38.964 32.1035 38.6209 32.5269 38.6209H34.8225C35.2446 38.6209 35.5877 38.964 35.5877 39.3861V49.4637" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0595 49.4638H35.748C36.4524 49.4638 37.0234 50.0348 37.0234 50.7391V64.3837H10.7842V50.7391C10.7842 50.0348 11.3551 49.4638 12.0595 49.4638Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6519 64.3838V57.5174C17.6519 56.9537 18.1084 56.4972 18.6721 56.4972H29.1349C29.6986 56.4972 30.1551 56.9537 30.1551 57.5174V58.2086" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.1152 40.2523L23.5466 44.7488C23.9675 45.0036 24.4976 44.9934 24.9085 44.7226L31.6919 40.2523" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M30.1548 64.3837V62.6722" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
