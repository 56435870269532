import { configureStore } from '@reduxjs/toolkit'
import farmerSlice from './slices/farmerSlice';
import searchSlice from './slices/searchSlice';
import productSlice from './slices/productSlice';
import standSlice from './slices/standSlice';
import marketInfoSlice from './slices/marketInfoSlice';
import badgeSlice from './slices/badgeSlice';

export const store = configureStore({
    reducer: {
        search:searchSlice,
        farmer:farmerSlice,
        product:productSlice,
        stand:standSlice,
        marketInfo:marketInfoSlice,
        badge:badgeSlice,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
