import {Row, Col,Button,Container,InputGroup,FormControl } from 'react-bootstrap';
import search from "./../../assets/logos/search.svg";
import { NavLink } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setSearch } from "../../store/slices/searchSlice";

export default function ViewerHeaderRightComponent() {
    const dispatch = useDispatch();

  const handleChange = (e) => {
if (e.target.value.length>0) {
    dispatch(setSearch(e.target.value));
}
else{
    dispatch(setSearch(''));
}
  };
  return (
    <Col xxl={5} xl={5} lg={5} className="d-flex justify-content-end viewerheader-right p-0">
                  <Row className="d-flex w-100 m-0 justify-content-end" style={{flexWrap:"nowrap"}}>
                     <Col xxl={3} xl={3} lg={3}  className=" m-0 px-0 py-2 d-flex align-items-center justify-content-end">
                         <NavLink to={"/"} id='link' className='h-50 px-3 fw-medium rounded text-white d-flex align-items-center home-button'>Ana Menü</NavLink>
                    </Col>   
                    <Col xxl={8} xl={8} lg={8} className="d-flex justify-content-end align-items-center pe-4 ps-0 py-2">
                        <Container className=" position-relative w-100 p-0  h-50">
                            <InputGroup className="position-relative w-100 h-100" style={{marginLeft:"1rem",paddingRight:"0rem"}} >
                                <Button className="position-absolute bg-transparent border-0 top-50 translate-middle" style={{left:"20px", zIndex:100}}>
                                    <img  src={search} alt="search"></img>
                                </Button>
                                <FormControl
                                    type="text"
                                    className="py-3 px-5 fs-6 fw-medium text-black search-input"
                                    placeholder="Arama Yap"
                                    aria-label="Ara"
                                    aria-describedby="basic-addon2"
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Container>
                    </Col>
                    </Row>      
                </Col>
  )
}
