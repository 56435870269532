/* eslint-disable react-hooks/exhaustive-deps */
import "../styles/ProductsPage.css"
import { useEffect, useState } from 'react'
import {Col, Row } from 'react-bootstrap'
import CustomizedComponent from '../components/Shared/CustomizedComponent'
import ModalProductItemComponent from '../components/ProductsPage/ModalProductItemComponent'
import { useProductsState } from "../store/accessors"
import ScrollBarComponent from "../components/Shared/ScrollBarComponent"
import CardComponent from "../components/Shared/CardComponent"
import { searchFilter, sortData } from "../services/utils"
import { useSelector } from "react-redux"
import { getProducts } from "../services/apis/product"

export default function ProductsPage() {
  
  const products = useProductsState();
  
  useEffect(async() => {
    if (products?.length>0) {
      setLoading(false);
    } else {
      var result = await getProducts();
      setLoading(result && false );
    }
    }, []);
    
    const [loading,setLoading]=useState(true); 
    const [showModal,setShowModal] = useState(false);
    const [selectedId,setSelectedId] = useState('');
    const searchValue = useSelector((state) => state.search.value);
    const [filterItems,setFilterItems] = useState([{
      id:"56165165165265",
      name:"30 ₺ ve altı",
      price:30,
      selected:false,
    },
    {
      id:"615616165621651",
      name:"50 ₺ ve altı",
      price:50,
      selected:false,
    },]);

    const [filterList,setFilterList]=useState(products);
    
    const sortClick = (desc) => {
      const sortedStalls = sortData(products, 'name', desc);
      setFilterList(sortedStalls);
    };

    const cardClick = (id)=>{
        setSelectedId(id);
        setShowModal(true);
    }

    const filterClick = (item)=>{
      const itemList=filterItems;
      const findItem=itemList.find(x=>x.id===item?.id);
      findItem.id=item?.id;
      setFilterItems(itemList);
      setFilterList(filteredProducts(products,itemList));
    }

    const filteredProducts = (products, items) => {
    const selectedItems = items.filter((x) => x.selected === true);
    const filteredProducts = products.filter((product) => {
    return selectedItems.every((selectedItem) => {
      return parseInt(product?.minPrice) <= parseInt(selectedItem?.price);
    });
  });

  return filteredProducts;
};

useEffect(()=>{
  setFilterList(products);
},[products])

const matchingProducts = filterList?.filter((s) => searchFilter(s, searchValue) && s?.minStock);
const matchingProductsCount = matchingProducts?.length;

  return (
    <div className='px-4' style={{overflowY:"hidden"}} >
        <Row className='w-100'>
          <div className='w-100 p-0'>
                <CustomizedComponent filterClick={filterClick} filterItems={filterItems}  click={sortClick}/>
           </div>
           <ScrollBarComponent>
                <Row className='gx-5 px-2 ms-1 gy-4 py-4 pe-0' style={{width:"95%"}}>
                 <>
                    {
                      loading ? 
                      <div className="fs-1 w-100 d-flex justify-content-center align-items-center" style={{height:"50vh"}}>Yükleniyor...</div>
                       : <> {matchingProductsCount > 0 ? matchingProducts?.map((item, index) => ( 
                        <Col key={index} xxl={4} xl={4} lg={4} className='m-0 mb-3 mt-2 card-container'>
                          <Row className='h-100 pages-card-container'>
                            <CardComponent shadow={"products-page-shadow"} logo={"Products"} description={`${item?.minPrice && item?.maxPrice ?  (`Min: ${parseFloat(item?.minPrice).toFixed(2)} ₺ - Max: ${parseFloat(item?.maxPrice).toFixed(2)} ₺` ) : ""}`} data={item} key={index} cardClick={cardClick} topInfo={item?.minStock>0 && "Stokta Var"}/>
                          </Row>
                        </Col>
                      )):<div className="fs-1 w-100 d-flex justify-content-center align-items-center ps-5" style={{height:"50vh"}}>Pazar kurulumu yok ya da ürün mevcut değil.</div>} </>
                    }
                 </>
                </Row>
            </ScrollBarComponent>
        </Row>
        <ModalProductItemComponent selectedId={selectedId} showModal={showModal} setShowModal={setShowModal}></ModalProductItemComponent>
    </div>
  )
}
