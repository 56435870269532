import {Modal,Button,Row,Col,Form} from 'react-bootstrap';
import ModalInputComponent from '../Modals/ModalInputComponent';
import ModalButtonGroupComponent from '../Modals/ModalButtonGroupComponent';
import ModalHeaderComponent from '../Modals/ModalHeaderComponent';
import ModalPhotoDisplayComponent from '../Modals/ModalPhotoDisplayComponent';
import ModalCloseComponent from '../Modals/ModalCloseComponent';
import ModalComponent from '../Modals/ModalComponent';
import ModalSendMessageComponent from "../Modals/ModalSendMessageComponent"
import { useState } from 'react';

import ModalStandProductDetailComponent from './ModalStandProductDetailComponent';

export default function ModalStandDetailComponent({showModal,setShowModal,data}) {

  const [showProductsModal,setShowProductsModal] = useState(false); 
  const [showModalSendMessage,setShowModalSendMessage] = useState(false);
  const [selectedProduct,setSelectedProduct] = useState(0);
  const containsNumber = /\d/.test(data?.landName);

  const handleClose = () => setShowModal(false);
  const handleCloseSendMessage = () => setShowModalSendMessage(false);


const productionsClick = (id,index)=>{
    const filteredItem = data?.whatDidIBrang[index];
    setSelectedProduct(filteredItem);
    setShowProductsModal(true);
}
  return (
        <ModalComponent showModal={showModal} handleClose={handleClose}  backgroundColor="rgba(0, 67, 140,0.5)">
           <ModalHeaderComponent title={data?.fullName} subTitle={data?.badges && data?.badges.join(",  ")} logo={"Farmer"} qrCode={data?.qrCode} standNumber={data?.marketStallName}/>
             <ModalCloseComponent handleClose={handleClose}></ModalCloseComponent>
            <Modal.Body className='px-4'>
                <Row className='position-relative h-100'>
                    <Col xxl={6} xl={6} lg={6}>
                        <Form>
                            <ModalInputComponent label={"Yaşadığım İl / İlçe Mahalle"} placeholder={data?.city} ></ModalInputComponent>
                            <ModalInputComponent label={"Tarım Arazimin Yüzölçümü"} placeholder={data?.landName + (containsNumber ? " Dekar":"")} />
                            <ModalInputComponent label={"Bağlı Bulunduğum Kooperatifler"} placeholder={data?.cooperativeName?.join(", ")} />
                            {data?.whatDidIBrang?.length>0 && <ModalButtonGroupComponent click={productionsClick} label={"Bugün Size Neler Getirdim"} data={data?.whatDidIBrang}/>}
                        </Form>
                    </Col>

                    <div className='position-absolute bg-black h-100 p-0 m-0 x-line' style={{width:"1px",left:"50%"}}></div>
                        <div className='position-absolute p-0 m-0  w-100 d-flex justify-content-center align-items-center' style={{height:"1px",top:"-2%"}}>
                                <div className='bg-black h-100 y-line' style={{width:"98%"}}></div>
                        </div>

                    <Col xxl={6} xl={6} lg={6} className='h-100 d-flex flex-column justify-content-between'>
                    {data?.producedProductsPhotos?.filter(file => file.extension === '.jpg' || '.png' || 'jpeg').length > 0  && <ModalPhotoDisplayComponent label={"Ürettiğim Ürünler"} customClass="production-photos" images={data?.producedProductsPhotos} />}
                        <section className='w-100 h-100 d-flex pe-5 justify-content-end align-items-end'>
                            <Button onClick={() => setShowModalSendMessage(true)} className='fw-bold fs-3 border-0 p-2 mt-4 px-5 mb-3' style={{backgroundColor:"rgba(64, 131, 32, 1)"}}>
                               Çiftçiye Mesaj İlet
                            </Button>
                            <ModalSendMessageComponent marketStallId={data?.marketStallId} farmerId={data?.farmerId} showModal={showModalSendMessage} setShowModal={setShowModalSendMessage} handleClose={handleCloseSendMessage}></ModalSendMessageComponent>
                        </section>   
                    </Col>
                </Row>
            </Modal.Body>
            
        {showProductsModal &&<ModalStandProductDetailComponent data={selectedProduct} showModal={showProductsModal} setShowModal={setShowProductsModal} farmer={data?.fullName} standNumber={data?.marketStallName} qrCode={data?.qrCode}/>}
        </ModalComponent>
  )
}
