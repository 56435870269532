/* eslint-disable react-hooks/exhaustive-deps */
import "../styles/FarmerPage.css"
import { Alert, Col, Row } from "react-bootstrap";
import { useState,useEffect } from "react";

import ModalFarmerDetailComponent from '../components/FarmerPage/ModalFarmerDetailComponent'
import CustomizedComponent from '../components/Shared/CustomizedComponent'
import RosettesComponent from '../components/FarmerPage/RosettesComponent';
import { useBadgesState, useFarmersState } from "../store/accessors";
import ScrollBarComponent from "../components/Shared/ScrollBarComponent";
import { searchFilter, sortData } from "../services/utils";
import CardComponent from "../components/Shared/CardComponent";
import { useSelector } from "react-redux";
import { getFarmers } from "../services/apis/farmer";

export default function FarmerPage() {
  
  const farmers = useFarmersState();
  useEffect(async() => {
        if (farmers?.length > 0) {
          setLoading(false);
        } else {
            const result = await getFarmers();
            setLoading(result && false);
        }
 
    }, []);

    const badges = useBadgesState();
    const [loading,setLoading]=useState(true);
    const [showPopUp,setShowPopUp] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const [showModal,setShowModal] = useState(false);
    const [selectedId,setSelectedId] = useState('');
    const searchValue = useSelector((state) => state.search.value);
    const [filterItems,setFilterItems]=useState(null);
    const filterBtnDefaultItems = [
      {
        id:"5656156156154544",
        name:"2 veya daha fazla rozeti olanlar",
        length:2,
        selected:false
      },
      {
        id:"4629486315894986",
        name:"4 veya daha fazla rozeti olanlar",
        length:4,
        selected:false
      },
    ];

    const badgeNewProperty= badges?.map((badge)=>({...badge,selected:false}))

    const combinedItems = [...filterBtnDefaultItems, ...badgeNewProperty];
    const [filterList,setFilterList]=useState(farmers);

    const cardClick = (id,errorMessage)=>{
    if (id) {
      setSelectedId(id);
      setShowModal(true);
    } else {
      setErrorMessage(errorMessage);
      setShowPopUp(true);
      setTimeout(()=>{
      setShowPopUp(false);
      },1000);
    } 
    }

    const sortClick = (desc) => {
      const sortedStalls = sortData(farmers, 'marketStallName', desc);
      setFilterList(sortedStalls);
    };

    const filterClick=(item)=>{
      const itemList=filterItems;
      const findItem=itemList?.find(x=>x?.id===item?.id);
      findItem.id=item?.id;
      setFilterItems(itemList);
      setFilterList(filteredFarmers(farmers,itemList))
    }  

const filteredFarmers = (farmers, items) => {
  const selectedItems = items?.filter((x) => x?.selected === true);
  if(selectedItems?.length===0){
    return farmers;
  }

  const filteredFarmers = farmers?.filter((farmer) => {
    return selectedItems?.every((selectedItem) => {
      const hasLength = selectedItem.hasOwnProperty('length');
      const farmerBadgesLength = farmer?.badges?.length;
      if (hasLength) {
        return farmerBadgesLength >= selectedItem.length;
      } else {
        return farmer?.badges?.includes(selectedItem?.name);
      }
    });
  });
  return filteredFarmers;
};

const badgesClick = (id) => {
    const filteredData = id
      ? farmers?.filter((item) => item?.badges?.includes(id))
      : farmers;
    setFilterList(filteredData);
  };

  useEffect(()=>{
    setFilterList(farmers);
    setFilterItems(combinedItems);
  },[farmers])

  return (
    <div className='px-4'>
        <Row className='w-100 h-100'>
           <div className='w-100 p-0'>
            <CustomizedComponent  filterClick={filterClick} filterItems={filterItems} click={sortClick}>
              <RosettesComponent click={badgesClick}/>
            </CustomizedComponent>
           </div> 
           <ScrollBarComponent>
                <Row className='gx-5 px-2 ms-1 gy-4 py-4 pe-0' style={{width:"95%"}}>
               <>
        {loading ? <div className='w-100 d-flex justify-content-center align-items-center fs-1' style={{height:"70vh"}}>Yükleniyor...</div> : <>
               {showPopUp && <Alert className="bg-danger position-absolute bottom-0 text-white w-25 fs-6 text-center z-1" style={{ right: "0px" }}>{errorMessage}</Alert>}
                  {filterList?.filter((item) => searchFilter(item, searchValue))?.map((item, index) => (
                      <Col key={index} xxl={4} xl={4} lg={4} className="m-0 mb-3 mt-2 card-container">
                        <Row className="h-100 pages-card-container">
                          <CardComponent logo={"Farmer"} shadow={"farmer-page-shadow"} description={`Çiftçi Hakkında Detaylı Bilgi Al`} cardClick={cardClick} data={item}/>
                        </Row>
                  </Col>))}
               </>}
               </>   
                </Row>
            </ScrollBarComponent>
        </Row>
        <ModalFarmerDetailComponent selectedId={selectedId} showModal={showModal} setShowModal={setShowModal}/>
    </div>
  )
}
